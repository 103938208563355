import {
	createRouter,
	createWebHistory
} from 'vue-router';

import Login from './components/login.vue';
import Home from './components/Home.vue';
import cs from './components/cs.vue';
//import Welcome from './components/welcome.vue';
import Rights from './components/power/Rights.vue';
import roles from './components/power/roles.vue';
//权限管理
import Admin from './components/admin/index.vue';
import AdminEdit from './components/admin/edit.vue';
//菜单管理
import Menu from './components/menu/index.vue';
import MenuEdit from './components/menu/edit.vue';
//角色管理
import Role from './components/role/index.vue';
//西药商品view
import Goods from './components/goods/index.vue';
import GoodsAdd from './components/goods/add.vue';
import GoodsEdit from './components/goods/edit.vue';
//中药商品view
import Herbs from './components/herbs/index.vue';
import HerbsAdd from './components/herbs/add.vue';
import HerbsEdit from './components/herbs/edit.vue';
//文章view
import Article from './components/article/index.vue';
import ArticleAdd from './components/article/add.vue';
import ArticleEdit from './components/article/edit.vue';
import Reptile from './components/reptile/index.vue';
//文章分组view
import Category from './components/category/index.vue';
//医生管理
import Doctor from './components/doctor/index.vue';
import DoctorAuth from './components/doctor/auth.vue';
//医院管理
import Hosp from './components/hosp/index.vue';
import HospAdd from './components/hosp/add.vue';
import HospEdit from './components/hosp/edit.vue';
//科室管理
import Class from './components/class/index.vue';
import ClassAdd from './components/class/add.vue';
import ClassEdit from './components/class/edit.vue';
//疾病管理
import Disease_1 from './components/disease/index_1.vue';
import Disease from './components/disease/index.vue';
import DiseaseAdd from './components/disease/add.vue';
import DiseaseEdit from './components/disease/edit.vue';
//问诊管理
import Ask from './components/ask/index.vue';
//患者管理
import User from './components/user/index.vue';
//手环管理
import Watch from './components/watch/index.vue';
//版本管理
import App from './components/app/index.vue';
//短信记录
import Sms from './components/sms/index.vue';
//全局配置管理
import ConfigEdit from './components/config/edit.vue';
//意见反馈
import Advise from './components/advise/index.vue';
//诊断模型
import Robot from './components/robot/index.vue';
//名医案例
import Cases from './components/cases/index.vue';
import CasesAdd from './components/cases/add.vue';
import CasesEdit from './components/cases/edit.vue';
//案列分组view
import CasesCategory from './components/cases/category.vue';

const routerHistory = createWebHistory();
//定义routes路由的集合，数组类型
const routes = [{
		path: '/',
		redirect: '/login'
	},
	{
		path: '/login',
		component: Login
	},
	{
		path: '/cs',
		component: cs
	},
	{
		path: '/Home',
		component: Home,
		//redirect: '/welcome',
		redirect: '/user/index',
		children: [{
				path: '/user/index',
				component: User
				// path: '/welcome',
				// component: Welcome
			},
			{
				path: '/rights',
				component: Rights
			},
			{
				path: '/roles',
				component: roles
			},
			{
				path: '/goods/index',
				component: Goods
			},
			{
				path: '/goods/add',
				component: GoodsAdd
			},
			{
				path: '/goods/edit',
				component: GoodsEdit
			},
			{
				path: '/herbs/index',
				component: Herbs
			},
			{
				path: '/herbs/add',
				component: HerbsAdd
			},
			{
				path: '/herbs/edit',
				component: HerbsEdit
			},
			{
				path: '/article/index',
				component: Article
			},
			{
				path: '/article/edit',
				component: ArticleEdit
			},
			{
				path: '/article/add',
				component: ArticleAdd
			},
			{
				path: '/reptile/index',
				component: Reptile
			},
			{
				path: '/admin/index',
				component: Admin
			},
			{
				path: '/admin/edit',
				component: AdminEdit
			},
			{
				path: '/role/index',
				component: Role
			},
			{
				path: '/role/edit',
				component: Role
			},
			{
				path: '/menu/index',
				component: Menu
			},
			{
				path: '/menu/edit',
				component: MenuEdit
			},
			{
				path: '/category/index',
				component: Category
			},
			{
				path: '/doctor/index',
				component: Doctor
			},
			{
				path: '/doctor/auth',
				component: DoctorAuth
			},
			{
				path: '/user/index',
				component: User
			},
			{
				path: '/app/index',
				component: App
			},
			{
				path: '/sms/index',
				component: Sms
			},
			{
				path: '/hosp/index',
				component: Hosp
			},
			{
				path: '/hosp/add',
				component: HospAdd
			},
			{
				path: '/hosp/edit',
				component: HospEdit
			},
			{
				path: '/disease/symptom',
				component: Disease_1
			},
			{
				path: '/disease/index',
				component: Disease
			},
			{
				path: '/disease/add',
				component: DiseaseAdd
			},
			{
				path: '/disease/edit',
				component: DiseaseEdit
			},
			{
				path: '/class/index',
				component: Class
			},
			{
				path: '/class/add',
				component: ClassAdd
			},
			{
				path: '/class/edit',
				component: ClassEdit
			},
			{
				path: '/watch/index',
				component: Watch
			},
			{
				path: '/ask/index',
				component: Ask
			},
			{
				path: '/config/edit',
				component: ConfigEdit
			}, {
				path: '/advise/index',
				component: Advise
			},
			{
				path: '/symptom/index',
				component: Robot
			},
			{
				path: '/cases/index',
				component: Cases
			},
			{
				path: '/cases/edit',
				component: CasesEdit
			},
			{
				path: '/cases/add',
				component: CasesAdd
			},
			{
				path: '/casesCategory/index',
				component: CasesCategory
			},
		]
	},
];
const router = createRouter({
	history: routerHistory,
	//mode: "history",//路由模式
	//base:'/lze/', // 注意此处是需要添加的,正常情况是没有的
	routes
});
//挂载路由导航守卫
router.beforeEach((to, from, next) => {
	//to 将要访问的路径
	//from 代表从那个路径跳转而来
	//next 是一个函数，表示放行
	//next() 放行  next('/login') 强制跳转到login页面

	if (to.path === '/login') return next(); //判断是否是login登录页面，是就直接放行
	//获取token
	const tokenStr = window.sessionStorage.getItem('token')
	if (!tokenStr) return next('/login'); //非登录页面判断是否有token，没有token强制返回login页面
	next()

})
//抛出这个这个实例对象方便外部读取以及访问
export default router
