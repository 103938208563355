<template>
  <!-- 面包屑导航 -->
  <el-breadcrumb>
    <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>权限管理</el-breadcrumb-item>
    <el-breadcrumb-item>权限列表</el-breadcrumb-item>
  </el-breadcrumb>
  <!-- 卡片视图区域 -->
  <el-card>
    <el-table :data="rightsList" border stripe>
      <el-table-column type="index"></el-table-column>
      <el-table-column label="权限名称" prop="authName"></el-table-column>
      <el-table-column label="权限路径" prop="path"></el-table-column>
      <el-table-column label="权限等级" prop="level">
        <template #default="scope">
          <el-tag v-if="scope.row.level == 0">等级一</el-tag>
          <el-tag v-if="scope.row.level == 1" type="success">等级二</el-tag>
          <el-tag v-if="scope.row.level == 2" type="warning">等级三</el-tag>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      rightsList: [],
    }
  },
  created() {
    this.getRightsList()
  },
  methods: {
    async getRightsList() {
      const { data: res } = await this.$axios.get('rights/list')
      if (res.meta.status != 200) {
        return this.$message.error('获取权限列表失败！')
      } else {
        this.rightsList = res.data
        console.log(this.rightsList)
      }
    },
  },
}
</script>

<style lang="less" scoped>
</style>
