<template>
	<div>
		<!-- 面包屑导航 -->
		<el-breadcrumb>
			<el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>配置管理</el-breadcrumb-item>
			<el-breadcrumb-item>配置编辑</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-col :span="12">
				<el-form label-position="left" label-width="80px">
					<el-form-item label="脉象参数">

						<el-input type="textarea" :rows="10" placeholder="请输入脉象参数" v-model="info.pulse" clearable>
						</el-input>
						<el-tooltip placement="top">
							<template #content>采用|线分割，前面2个参数设置区间值，最后面为提示值<br />80|100|平脉<br />0|60|脉象弱</template>
							<div>规则如何设置?</div>
						</el-tooltip>
					</el-form-item>
					<el-form-item label="体温参数">

						<el-input type="textarea" :rows="10" placeholder="请输入体温参数" v-model="info.temp" clearable>
						</el-input>
						<el-tooltip placement="top">
							<template #content>采用|线分割，前面2个参数设置区间值，最后面为提示值
								<br />36|37|正常<br />
								0|35.9|低烧</template>
							<div>规则如何设置?</div>
						</el-tooltip>
					</el-form-item>
					<el-form-item label="开启评论">
						<el-radio-group v-model="info.autostudy">
							<el-radio :label="0">允许</el-radio>
							<el-radio :label="1">禁止</el-radio>
						</el-radio-group>
					</el-form-item>


					<el-form-item>
						<el-button type="primary" @click="setArticle()">提交保存</el-button>
					</el-form-item>
				</el-form>
			</el-col>
		</el-card>
	</div>
</template>
<script>
	import {
		getConfig,
		setConfig,
	} from "../../api/app.js"
	export default {
		components: {},
		data() {
			return {
				info: ''
			}
		},
		created() {
			this.getInfo();
		},
		methods: {
			async setArticle() {
				console.log('编辑内容', JSON.parse(JSON.stringify(this.info)))
				this.info.id = 1
				await setConfig(this.info);
				this.$message.success('编辑成功');
			},

			/**
			 * 获取配置信息
			 */
			async getInfo() {
				let res = await getConfig({
					id: 1
				});
				this.info = res.data
			},
		}
	}
</script>
<style lang="less">
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		width: 100px;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		text-align: center;
	}
</style>
