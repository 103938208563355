import {
	http
} from '../http.js'
/**
 * 获取列表
 * @param {Object} option
 */
export function askList(option) {
	return http('admin/ask/index', 'post', option)
}
/**
 * 编辑问诊
 * @param {Object} option
 */
export function askEdit(option) {
	return http('admin/ask/edit', 'post', option)
}
/**
 * 新增问诊 
 * @param {Object} option
 */
export function askAdd(option) {
	return http('admin/ask/add', 'post', option)
}
/**
 * 获取问诊回复列表
 * @param {Object} option
 */
export function askReply(option) {
	return http('admin/reply/index', 'post', option)
}
