import {
	http
} from '../http.js'
//文章列表
export function index(option) {
	return http('admin/article/index', 'post', option)
}
//文章详情
export function details(option) {
	return http('admin/article/details', 'post', option)
}
//文章新增
export function add(option) {
	return http('admin/article/add', 'post', option)
}
//文章编辑
export function edit(option) {
	return http('admin/article/edit', 'post', option)
}
//文章删除
export function delect(option) {
	return http('admin/article/delect', 'post', option)
}
//分类列表
export function category(option) {
	return http('admin/category/index', 'post', option)
}
//分类编辑
export function categoryEdit(option) {
	return http('admin/category/edit', 'post', option)
}
//分类新增
export function categoryAdd(option) {
	return http('admin/category/add', 'post', option)
}
//分类列表(根据pid取对应分类)
export function getCategory(option) {
	return http('admin/category/getCategory', 'post', option)
}
//分类删除
export function categoryDel(option) {
	return http('admin/category/delect', 'post', option)
}