import {
	http
} from '../http.js'

//�в�ҩ�б�
export function index(option) {
	return http('admin/herbs/index', 'post', option)
}
//�в�ҩ����
export function details(option) {
	return http('admin/herbs/details', 'post', option)
}
//�в�ҩ����
export function add(option) {
	return http('admin/herbs/add', 'post', option)
}
//�в�ҩ����
export function edit(option) {
	return http('admin/herbs/edit', 'post', option)
}
//�в�ҩɾ��
export function delect(option) {
	return http('admin/herbs/delect', 'post', option)
}