<template>
	<!-- 面包屑导航 -->
	<div class="main">
		<el-breadcrumb>
			<el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>医生管理</el-breadcrumb-item>
			<el-breadcrumb-item>医生列表</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-container>
				<el-aside>
					<!-- table输出视图 -->
					<!-- <el-button :model="addForm" type="success" @click="dialogVisible = true">添加应用</el-button> -->
					<!-- <el-button type="danger" @click="delect()">禁 用</el-button> -->
					<!-- <el-button type="warning" @click="delect()">导出</el-button> -->
					<el-table :data="tableData" @selection-change="tableSelect" border stripe>
						<!-- <el-table-column type="selection" width="55"></el-table-column> -->
						<el-table-column type="index" :index='indexMethod' label="编号"></el-table-column>
						<el-table-column v-for="(items,i) in tableDataTemp" :key="i" :prop="i" :label="items.name">
							<template #header v-if="items.show==true">
								<span>
									{{items.name}}
								</span>
							</template>
							<template #default="scope">
								<div v-if="items.type=='array'">
									<el-tag type="info" v-for="arr in scope.row[i]" :key="arr.id">
										{{arr.name}}
									</el-tag>
								</div>
								<div v-else-if="items.type=='image'">
									<div class="block">
										<el-avatar shape="square" :size="50" :src="scope.row[i]"></el-avatar>
									</div>
								</div>
								<div v-else-if="items.type=='tag'">
									<div v-if="scope.row[i]==0">
										<el-tag type="danger">未认证</el-tag>
									</div>
									<div v-if="scope.row[i]==1">
										<el-tag type="success">已实名</el-tag>
									</div>
								</div>
								<div v-else-if="items.type=='select'">
									<el-switch v-model="scope.row[i]" @change="switchChange(scope.row)"
										active-color="#13ce66" inactive-color="#ff4949">
									</el-switch>
								</div>
								<div v-else>
									<span v-if="items.show==true">
										{{scope.row[i]}}
									</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="180px">
							<template #default="scope">
								<el-button type="primary" icon="el-icon-edit" size="mini"
									@click="showEditDialog(scope.$index, scope.row)">查看
								</el-button>
								<!-- <el-button type="danger" icon="el-icon-delete" size="mini" @click="removrRow(scope.row.id)">
							</el-button> -->

							</template>
						</el-table-column>
					</el-table>
					<!-- 分页区域 -->
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="queryInfo.pagenum" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>

				</el-aside>
				<el-main>
					<!-- 右则输出视图 -->
					<!-- {{editForm}} -->
					
					<!-- 右则输出视图 -->


							<el-card class="box-card" v-if="editForm">
								<template #header>
									<div class="card-header">
										<div class="row user-info">
											<div class="left">
												<div class="avatar">
													<!-- <span>{{editForm.name}}</span> -->
												</div>
												<div class="info">
													<div class="name">
														<span>{{editForm.name}}</span>
													</div>
													<span class="idcard">
														<span>{{editForm.idcard}}</span>
													</span>
												</div>
											</div>
										</div>
										
									</div>
								</template>
								<div>
									<div class="row">
										<div class="time">
											<span class="lable">提交时间 : </span>
											<span>{{editForm.create_time}}</span>
										</div>
									</div>
									<div class="row">
										<div class="card">
											<span class="title">相关资料</span>
											<div class="describe">
												<!-- {{editForm.content}} -->
											</div>
										</div>
										<div class="pic">
											
												
						
										</div>
									</div>
								</div>
								<el-popconfirm title="是否确认通过该医生入驻？" @confirm="adopt">
									<template #reference>
										<el-button type="primary" icon="el-icon-edit" size="mini">
											审核通过
										</el-button>
									</template>
								</el-popconfirm>
								<el-popconfirm title="是否驳回该医生申请" @confirm="reject()">
									<template #reference>
										<el-button class="el-button el-button--danger" type="primary" icon="el-icon-edit"
											size="mini">
											审核驳回
										</el-button>
									</template>
								</el-popconfirm>
							</el-card>
		
					<!-- 右则输出视图 END -->
					

					<!-- 右则输出视图 END -->
				</el-main>
			</el-container>
		</el-card>
		<!-- 卡片视图区域 -->
	</div>
</template>
<script>
	import {
		userEdit,
		doctorAuthList,
		doctorAuthAdopt,
	} from '../../api/user.js'
	export default {
		data() {
			return {
				defaultProps: {
					children: 'children',
					label: 'title',
				},
				treeData: [],
				options: [{
					value: '1', //不加双引号，el-select标签v-model绑定不了1 
					label: '正常'
				}, {
					value: '0',
					label: '禁止'
				}],
				shortcuts: [{
					text: '最近一周',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						return [start, end]
					}
				}, {
					text: '最近一个月',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						return [start, end]
					}
				}, {
					text: '最近三个月',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						return [start, end]
					}
				}],
				//获取表格参数对象
				queryInfo: {
					query: {},
					//当前的页面
					pagenum: 1,
					//当前每页显示多少条数据
					pagesize: 10,
				},
				//接口表格数据
				tableData: [],
				//本地表格临时数据
				tableDataTemp: {},
				//选中的row
				tableRow: [],

				//支持搜索的字段
				search: {
					status: false, //开启表单搜索
					list: {
						name: {
							show: true, //表格是否显示字段
							search: true,
							modal: true,
							type: 'text', //列类型,决定了搜索和显示的样式
							name: '姓 名', //列名称
							value: '', //当前搜索情况下的值
							//验证规则
							rules: [{
									required: true,
									message: '请输入手机号码',
									trigger: 'blur',
								},
								{
									min: 11,
									max: 11,
									message: '请输入正确手机号码',
									trigger: 'blur',
								},
							]
						},
						create_time: {
							show: true, //表格是否显示字段
							search: true,
							modal: true,
							type: 'time',
							name: '注册时间',
							value: '',
							//验证规则
							rules: [{
									required: true,
									message: '请输入日期',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},

					}

				},
				//状态
				total: 0,
				//权限模态框状态
				treeDialogVisible: false,
				//模态框新增状态
				dialogVisible: false,
				//模态框编辑状态
				editDealogVisible: false,
				//当前编辑对象
				editForm: '',
				//当前新增对象
				addForm: {},
				editRow: {},
			}
		},
		created() {
			this.initTable();
			this.getTableData(false);
		},
		methods: {
			/**
			 * 审核通过
			 */
			async adopt() {
				let res = await doctorAuthAdopt({
					id: this.editForm.id,
					auth:1,
					reason:'经平台核实，您为持证医生，审核通过！'
				});
				console.log('点击了审核通过,id是', res)
				return this.$message.success('审核成功');
				
			},
			/**
			 * 驳回审核
			 */
			async reject() {
				let res = await doctorAuthAdopt({
					id: this.editForm.id,
					auth:2,
					reason:'资料不太完善和正确，无法给你上岗！'
				});
				console.log('点击驳回审核,id是', res)
				return this.$message.success('驳回审核');
			},
			/**
			 * 初始化表格数据
			 * 为配合动态渲染表格列，需生成tableDataTemp渲染
			 */
			initTable() {
				let _this = this;
				Object.keys(this.search.list).forEach(function(key) {
					if (_this.search.list[key].show == true) {
						_this.tableDataTemp[key] = _this.search.list[key];
					}
				});
			},
			indexMethod(index) {
				let curpage = this.queryInfo.pagenum; //单前页码，具体看组件取值
				let limitpage = this.queryInfo.pagesize; //每页条数，具体是组件取值
				return (index + 1) + (curpage - 1) * limitpage;
			},
			searchSelect(value) {
				console.log('您下拉选择了！', value);
				this.search.list.status.value = value;
				console.log('最新状态', this.search.list.status.value);
			},
			/**
			 * 加载接口数据
			 * value 是否搜索
			 */
			async getTableData(value) {
				let d = {};
				if (value) {
					//过滤筛选条件
					Object.keys(this.search.list).forEach((key) => {
						if (this.search.status == true) {
							if (this.search.list[key].value != '') {
								//console.log(key+'资料',this.search.list[key].value);
								d[key] = {
									'value': this.search.list[key].value,
									'type': this.search.list[key].type,
								}
							}
						}
					});
				}
				//默认追加角色获取数据
				d['auth'] = {
					type: 'text',
					value: 0
				};
				let res = await doctorAuthList({
					filter: JSON.stringify(d),
					page: this.queryInfo.pagenum,
					limit: this.queryInfo.pagesize
				});
				this.tableData = res.data.list
				this.total = res.data.pagination.total
			},
			/**
			 * @param {Object} val
			 * 监听pagesize改变事件
			 */
			handleSizeChange(val) {
				this.queryInfo.pagesize = val
				this.getTableData()
			},
			/**
			 * @param {Object} val
			 * 监听页码值改变事件
			 */
			handleCurrentChange(val) {
				this.queryInfo.pagenum = val
				this.getTableData()
			},
			/**
			 * @param {Object} row
			 * 监听swit开关的改变状态
			 */
			async switchChange(row) {
				//开始实现你的伟大逻辑...
				console.log('switch切换成功', row.status);
				await userEdit({
					id: row.id,
					status: row.status,
				});
				return this.$message.success('切换成功');
			},
			/**
			 * 返回一维数组某列
			 * @param {Object} name 需要返回的属性名
			 * @param {Object} arr 原始数据
			 */
			getParamValues(name, arr) {
				var ret = []
				for (var i = 0, len = arr.length; i < len; i++) {
					ret.push(arr[i][name])
				}
				return ret
			},
			/**
			 * 渲染右则数据
			 */
			showEditDialog(index, row) {
				console.log('编辑行数据', row);
				row.password = ''; //清空密码默认值
				this.editForm = JSON.parse(JSON.stringify(row));
				this.editDealogVisible = true;
			},
			/**
			 * 关闭表单编辑模态框
			 */
			edotDialogClosed() {
				this.$refs.editFormRef.resetFields()
			},
			/**
			 * 表单编辑
			 */
			tableEditData() {
				console.log('编辑的数据', JSON.stringify(this.editForm));
				this.$refs.editFormRef.validate(async (valid) => {
					if (valid) {
						await userEdit(
							this.editForm
						);
						this.editDealogVisible = false;
						this.getTableData(false)
						return this.$message.success('编辑成功');
					} else {
						console.log('验证不通过');
						return false;
					}

				})
			},



			/**
			 * 删除选中行
			 * @param {Object} id
			 */
			async removrRow(id) {
				//弹框提示
				const confirmResult = await this.$confirm(
					'此操作将永久删除该用户, 是否继续?',
					'提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					}
				).catch((err) => err)
				if (confirmResult != 'confirm') {
					//取消操作
				} else {
					this.$message.success('删除成功,ID:' + id)

				}
			},
			/**
			 * 选项改变触发事件
			 * @param {Object} row
			 */
			tableSelect(row) {
				this.tableRow = [];
				Object.keys(row).forEach((key) => {
					this.tableRow[key] = row[key].id;
					//console.log(this.tableRow[key]);
				});

			},
			/**
			 * 删除选中行
			 */
			delect() {
				if (this.tableRow.length <= 0) {
					return this.$message.error("请选择删除行")
				} else {
					//开始实现你的伟大逻辑...
					console.log('删除成功ids', JSON.stringify(this.tableRow));
				}

			},
		},
	}
</script>
<style lang="less" scoped>
	.el-aside {
		width: 600px !important;
		padding: 1.25rem;
		background-color: #FFFFFF;
		margin-right: 10px;
	}

	.el-card {
		background-color: #f5f7f9;
	}

	.el-pagination {
		margin-top: 15px;
	}

	.el-main {
		background-color: #FFFFFF;

	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-tag {
		margin-left: 4px;
		margin-bottom: 4px;
	}

	.tisp {
		color: orangered;
		font-size: 12px;
	}
</style>
