<template>
  <!-- 面包屑导航 -->
  <el-breadcrumb>
    <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>权限管理</el-breadcrumb-item>
    <el-breadcrumb-item>角色列表</el-breadcrumb-item>
  </el-breadcrumb>
  <!-- 卡片视图区域 -->
  <el-card>
    <!-- 添加角色按钮 -->
    <el-row>
      <el-col>
        <el-button type="primary">添加角色</el-button>
      </el-col>
    </el-row>
    <!-- 角色列表 -->
    <el-table :data="rolesLis" border stripe>
      <!-- 展开列 -->
      <el-table-column type="expand">
        <template #default="scope">
          <el-row
            :class="['dbbottom', i1 == 0 ? 'dbtop' : '', 'vcenter']"
            v-for="(item1, i1) in scope.row.children"
            :key="item1.id"
          >
            <!-- 宣染一级权限 -->
            <el-col :span="5">
              <el-tag
                type="primary"
                closable
                @close="removeRightEyId(scope.row, item1.id)"
              >
                {{ item1.authName }}
              </el-tag>
              <i class="el-icon-caret-right"></i>
            </el-col>
            <!-- 渲染二级和三级权限 -->
            <el-col :span="19">
              <!-- 通过for循环嵌套渲染二级权限-->
              <el-row
                :class="[i2 == 0 ? '' : 'dbtop', 'vcenter']"
                v-for="(item2, i2) in item1.children"
                :key="item2.id"
              >
                <el-col :span="6">
                  <el-tag
                    type="success"
                    closable
                    @close="removeRightEyId(scope.row, item2.id)"
                  >
                    {{ item2.authName }}
                  </el-tag>
                  <i class="el-icon-caret-right"></i>
                </el-col>
                <!-- 通过for循环嵌套渲染三级权限-->
                <el-col :span="18">
                  <el-tag
                    type="warning"
                    v-for="item3 in item2.children"
                    :key="item3.id"
                    closable
                    @close="removeRightEyId(scope.row, item3.id)"
                  >
                    {{ item3.authName }}
                  </el-tag>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <!-- 索引列 -->
      <el-table-column type="index"></el-table-column>
      <el-table-column prop="roleName" label="角色名称"></el-table-column>
      <el-table-column prop="roleDesc" label="角色描述"></el-table-column>
      <el-table-column label="操作" width="300px">
        <template #default="scope">
          <el-button size="mini" type="primary" icon="el-icon-edit"
            >编辑</el-button
          >
          <el-button size="mini" type="danger" icon="el-icon-delete"
            >删除</el-button
          >
          <el-button
            size="mini"
            type="warning"
            icon="el-icon-s-tools"
            @click="showSerRightDialog(scope.row)"
          >
            分配权限
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <!-- 分配权限的对话框 -->
  <el-dialog
    title="分配权限"
    v-model="setRighDialogVisible"
    width="50%"
    @close="setRightDialogClosed"
  >
    <!-- 树形控件 -->
    <el-tree
      :data="rightslist"
      :props="treeProps"
      show-checkbox
      node-key="id"
      default-expand-all
      :default-checked-keys="defKeys"
      ref="treeRef"
    >
    </el-tree>
    <template #footer>
      <span class="dialog-footer">
    2    <el-button @click="setRighDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="allotRights()">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      //所有角色列表数据
      rolesLis: [],
      //控制分配权限的隐藏已打开
      setRighDialogVisible: false,
      //所有权限的数据
      rightslist: [],
      //树形控件属性绑定对象
      treeProps: {
        label: 'authName',
        children: 'children',
      },
      //默认选中的节点id值数组
      defKeys: [],
      //当前即将分配权限的id
      roleId: '',
    }
  },
  created() {
    this.getRolesLis()
  },
  methods: {
    //获取所有角色列表
    async getRolesLis() {
      const { data: res } = await this.$axios.get('roles')
      if (res.meta.status != 200) {
        return this.$message.error('请求角色列表失败')
      } else {
        this.rolesLis = res.data
      }
    },
    //根据id删除对应的权限
    async removeRightEyId(role, rightId) {
      //弹框提示用户是否要删除
      const confirmResult = await this.$confirm(
        '此操作将永久删除该文件, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)
      //
      if (confirmResult !== 'confirm') {
        return this.$message.info('用户取消了操作')
      }
      // console.log('用户点击了确定')
      const { data: res } = await this.$axios.delete(
        `roles/${role.id}/rights/${rightId}`
      )
      if (res.meta.status !== 200) {
        return this.$message.error('删除失败')
      }
      role.children = res.data
    },
    // 展开分配权限的对话框
    async showSerRightDialog(role) {
      //获取展开id
      this.roleId = role.id
      //获取所有权限的数据
      const { data: res } = await this.$axios.get('rights/tree')
      if (res.meta.status !== 200) {
        return this.$message.error('获取分配权限失败')
      }
      //把获取到的数据保存到data中
      this.rightslist = res.data
      console.log(this.rightslist)
      //递归获取三级节点的id
      this.getLeafKeys(role, this.defKeys)
      //打开对话框
      this.setRighDialogVisible = true
    },
    //通过递归的形式，获取角色下所有三级权限的id，并保存到defKeys 数组中
    getLeafKeys(node, arr) {
      //如果当前 node 节点不包含 children 属性，则是三级节点
      if (!node.children) {
        return arr.push(node.id)
      }
      node.children.forEach((item) => this.getLeafKeys(item, arr))
    },
    //监听分配权限对话框的关闭事件
    setRightDialogClosed() {
      this.defKeys = []
    },
    //点击为角色分配权限
    async allotRights() {
      const keys = [
        ...this.$refs.treeRef.getCheckedKeys(),
        ...this.$refs.treeRef.getHalfCheckedKeys(),
      ]
      //分隔符
      const idStr = keys.join(',')
      //请求接口把数据递交
      const { data: res } = await this.$axios.post(
        `roles/${this.roleId}/rights`,
        { rids: idStr }
      )
      //
      if (res.meta.status !== 200) {
        return this.$message.info('分配权限失败')
      }
      this.$message.success('分配权限成功')
      //刷新列表
      this.getRolesLis()
      //关闭对话框
      this.setRighDialogVisible = false
    },
  },
}
</script>

<style lang="less" scoped>
.el-tag {
  margin: 7px;
}

.dbtop {
  border-top: 1px solid #eee;
}

.dbbottom {
  border-bottom: 1px solid #eee;
}

.vcenter {
  display: flex;
  align-items: center;
}
</style>
