import {
	http
} from '../http.js'

/**
 * 模型列表
 * @param {Object} option
 */
export function index(option) {
	return http('admin/symptom/index', 'post', option)
}
/**
 * 添加模型
 * @param {Object} option
 */
export function symptomAdd(option) {
	return http('admin/symptom/add', 'post', option)
}
/**
 * 编辑模型
 * @param {Object} option
 */
export function symptomEdit(option) {
	return http('admin/symptom/edit', 'post', option)
}
/**
 * 新增模型
 * @param {Object} option
 */
export function stepAdd(option) {
	return http('admin/symptom/stepAdd', 'post', option)
}
/**
 * 编辑模型
 * @param {Object} option
 */
export function stepEdit(option) {
	return http('admin/symptom/stepEdit', 'post', option)
}