<template>
	<div class="add">
		<!-- 面包屑导航 -->
		<el-breadcrumb separator=">">
			<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>药品管理</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: 'index' }">药品列表</el-breadcrumb-item>
			<el-breadcrumb-item>添加药品</el-breadcrumb-item>
		</el-breadcrumb>

		<!-- 卡片视图 -->

		<el-card>
			<!-- 提示框 -->
			<el-alert title="添加商品信息" type="info" :closable="false" center show-icon></el-alert>
			<!-- 步骤条 -->
			<el-steps :active="activeIndex - 0" finish-status="success" align-center>
				<el-step title="基本信息"></el-step>
				<el-step title="商品参数"></el-step>
				<el-step title="商品图片"></el-step>
				<el-step title="商品内容"></el-step>
			</el-steps>

			<!-- tab栏区域 -->
			<el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px"
				:label-position="position">
				<el-tabs tab-position="left" v-model="activeIndex" :before-leave="beforeTabLeave"
					@tab-click="tabClicked">
					<el-tab-pane label="基本信息" name="0">
						<el-form-item label="商品名称" prop="goods_name">
							<el-input v-model="addForm.goods_name"></el-input>
						</el-form-item>
						<el-form-item label="商品原价" prop="original">
							<el-input v-model="addForm.original" type="number"></el-input>
						</el-form-item>
						<el-form-item label="零售价" prop="price">
							<el-input v-model="addForm.price" type="number"></el-input>
						</el-form-item>

						<el-form-item label="商品分类" prop="category_id">
							<el-cascader v-model="addForm.category_id" :props="propCcategory" :options="category"
								@change="handleChange">
							</el-cascader>
						</el-form-item>


						<el-form-item label="适用症状">
							<el-select v-model="addForm.symptom" @change="cangeSymptoms" multiple filterable
								allow-create default-first-option placeholder="请输入药品适用症状" no-data-text="请输入症状/键盘回车确认">
								<!-- <el-option v-for="item in addForm.symptoms" :key="item.id" :label="item.name" :value="item.name">
								</el-option> -->
							</el-select>
						</el-form-item>
					</el-tab-pane>

					<el-tab-pane label="商品参数" name="1">
						<el-card class="box-card">
							<template #header>
								<div class="card-header">
									<el-checkbox @change="changeCheckbox" v-model="item.disabled" :label="item.title"
										v-for="(item,index) in property" :key="index" border></el-checkbox>
									<el-button @click="selectAll()" class="button" type="text">全部选择</el-button>
								</div>
							</template>

							<!--动态渲染属性值-->
							<el-form-item :label="item.title" border v-for="(item,index) in property" :key="index"
								v-show="item.disabled">
								<el-input placeholder="请输入内容" v-model="item.content">
								</el-input>
							</el-form-item>


						</el-card>
					</el-tab-pane>
					<el-tab-pane label="商品图片" name="2">
						<el-card>
							<div class="title">当前主图</div>
							<div class="cover" v-for="(item,index) in addForm.images" :key="index">
								<div v-if="item.is_master==1"><img :src="item.url" /></div>
							</div>
							<div class="title">图片列表</div>
							<el-upload multiple :limit="10" :action="imgUrl" :data="dataImg" :headers="imgHeader"
								:file-list="addForm.images" :before-upload="beforeUpload" :on-success="ImgSuccess"
								list-type="picture-card">
								<template #file="{file}">
									<div>
										<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
										<span class="el-upload-list__item-actions">
											<div class="view">
												<span class="el-upload-list__item-preview"
													@click="handlePictureCardPreview(file)">
													<i class="el-icon-zoom-in"></i>
												</span>
												<span v-if="!disabled" class="el-upload-list__item-delete"
													@click="handleRemove(file)">
													<i class="el-icon-delete"></i>
												</span>
											</div>
											<div class="btn">
												<el-button type="primary" @click="setImgeaIndex(file)">设置主图</el-button>
											</div>
										</span>
									</div>
								</template>
							</el-upload>
							<el-dialog v-model="dialogVisible">
								<img :src="dialogImageUrl" alt="">
							</el-dialog>
						</el-card>
					</el-tab-pane>
					<el-tab-pane label="商品内容" name="3">
						<!-- 富文本编辑器组件 -->
						<el-form-item label="商品内容">
							<editor :init="init" api-key='ck6c1h9io2rg4s79wg2w9wh8wgj1o1wkcg2flbwps11xaaoh'
								v-model="addForm.content">
							</editor>
						</el-form-item>
						<el-form-item label="">
							<el-button type="primary" class="btnAdd" @click="add()">添加商品</el-button>

						</el-form-item>
					</el-tab-pane>
				</el-tabs>
			</el-form>
		</el-card>

		<!-- 图片预览对话框 -->
		<!-- <el-card title="图片预览" width="50%">

			<img :src="previewPath" alt="" class="previewIng" />

		</el-dialog> -->
	</div>
</template>

<script>
	import {
		category,
		property,
		add
	} from "../../api/goods.js"
	import Editor from '@tinymce/tinymce-vue'
	export default {
		components: {
			'editor': Editor,
		},
		data() {
			return {
				value: '',
				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				imgUrl: this.$BASE_URL + '/common/upload/index', //主图上传地址
				dataImg: [], //上传文件参数
				imgHeader: [], //上传文件头信息
				position: 'top', //表单排序方向
				activeIndex: '0', //步骤下标
				//商品分类
				propCcategory: {
					label: 'cate_name',
					value: 'id',
					children: 'children'
				},
				category: [],
				//商品属性分类
				property: [], //前端临时属性记录
				//表单提交数据
				addForm: {
					goods_name: '',
					original: '',
					price: '',
					category_id: '',
					property: [],
					images:[]
				},
				//验证规则
				addFormRules: {
					goods_name: [{
						required: true,
						message: '请输入商品名称',
						trigger: 'blur'
					}],
					price: [{
						required: true,
						message: '请输入商品价格',
						trigger: 'blur'
					}],
					original: [{
						required: true,
						message: '请输入商品重量',
						trigger: 'blur'
					}],
					category_id: [{
						required: true,
						message: '请输入商品重量',
						trigger: 'blur'
					}],

				},
				//富文本配置
				init: {
					//plugins: ' lists image colorpicker textcolor wordcount contextmenu autoresize',
					plugins: ' lists image wordcount autoresize',
					toolbar: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent  | undo redo | link unlink image  | removeformat ',
					branding: false,
					min_height: 300,
					images_upload_url: '/demo/upimg.php',
					language: 'zh_CN', //注意大小写
				},
			}
		},
		created() {
			this.ids = this.$route.query.ids;
			this.getCategory();
			this.getProperty(); //获取商品属性
		},
		methods: {
			cangeSymptoms(e) {
				console.log(e);
			},
			/**
			 * 选择所有属性
			 */
			selectAll() {
				for (var i = 0; i < this.property.length; i++) {
					this.property[i].disabled = true;
				}

			},
			setImgeaIndex(file) {
				// 1.获取将要删除图片的临时路径
				const filePath = file.url
				// 2.从pics数组中，找到图片对应的索引值
				const i = this.addForm.images.findIndex(x => x.url === filePath);
				console.log('设置主图成功,下标是：', i);
			},
			/**
			 * 当点击属性名称时改变属性值输入视图
			 * @param {Object} e
			 */
			changeCheckbox(e) {
				// console.log('我们改变', this.property);
				console.log('改变了', e);
				//let arr = '';
				// for (var i = 0; i < this.property.length; i++) {
				// 	if (this.property[i].disabled == false) {
				// 		console.log('当前：', this.property[i]);
				// 	}
				// }
			},
			beforeTabLeave(activeName, oldActiveName) {
				if (activeName == 0) {
					this.position = 'top'
					this.$refs.addFormRef.validate(async valid => {
						if (!valid) {
							this.$message.error('哈哈哈...');
							return false
						} else {
							return true;
						}
					})
				} else if (oldActiveName == 2 && activeName == 3) {
					this.position = 'top'
				} else {
					this.position = 'right'
				}
				console.log('---', activeName)
				console.log('---', oldActiveName)
			},
			tabClicked() {
				//console.log('切换了分类');
			},
			/**
			 * 提交表单
			 */
			async add() {
				//过滤选中属性值
				let pro = [];
				for (let i = 0; i < this.property.length; i++) {
					if (this.property[i].disabled == 1) {
						pro.push(this.property[i]);
					}
				}
				this.addForm.property = pro;
				await add(this.addForm);
				this.$message.success("新增成功");
				//console.log(res);
				console.log('提交表单数据', this.addForm)
			},
			/**
			 * 监听商品分组选择
			 */
			handleChange(value) {
				this.addForm.category_id = value.pop();
				console.log('---', this.addForm.category_id);
			},
			/**
			 * 	点击上传文件预览时候钩子
			 */
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			/**
			 * 删除文件钩子
			 * @param {Object} file
			 */
			handleRemove(file) {
				// 1.获取将要删除图片的临时路径
				const filePath = file.url
				// 2.从pics数组中，找到图片对应的索引值
				const i = this.addForm.images.findIndex(x => x.url === filePath);
				// 3.调用splice方法，移除图片信息
				this.addForm.images.splice(i, 1)
			},
			/**
			 * 文件选中后钩子
			 * @param {Object} e
			 */
			beforeUpload(e) {
				console.log('上传头', this.imgHeader)
				console.log('文件选中后钩子',e);
			},
			/**
			 * 上传主图成功
			 * @param {Object} response
			 * @param {Object} file
			 * @param {Object} fileList
			 */
			ImgSuccess(response, file, fileList) {
				console.log('上传成功', response.data);
				console.log('上传成功file：', file);
				console.log('上传成功fileList：', fileList);
				response.data.url = response.data.cdn
				this.addForm.images.push(response.data);
			},
			/**
			 * 获取商品分类
			 */
			async getCategory() {
				let res = await category({
					page: 1,
					limit: 5000
				});
				this.category = res.data.list;
			},
			/**
			 * 获取属性名称列表
			 */
			async getProperty() {
				let res = await property({
					page: 1,
					limit: 5000
				});
				this.property = res.data.list;
				//根据商品返回动态选择属性
				for (var i = 0; i < this.property.length; i++) {
					this.property[i].disabled = false;
					for (let j = 0; j < this.addForm.property.length; j++) {
						if (this.property[i].id == this.addForm.property[j].prop_name_id) {
							this.property[i].disabled = true;
							this.property[i].content = this.addForm.property[j].content;
						}
					}
				}
			},

		}
	}
</script>
<style lang="less" scoped>
	.el-breadcrumb {
		margin-bottom: 15px;
	}

	.el-steps {
		margin: 15px 0;
	}

	.el-step__title {
		font-size: 13px;
	}

	.el-checkbox {
		margin: 10px 10px !important;
	}

	.previewIng {
		width: 100%;
	}

	.ql-editor {
		min-height: 300px;
	}

	.btnAdd {
		margin-top: 15px;
	}

	.el-upload-list__item-actions {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-top: 25%;

		.btn {
			border: none;
			color: #FFFFFF;
		}
	}
</style>
