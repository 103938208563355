<template>
	<!-- 面包屑导航 -->
	<el-breadcrumb>
		<el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
		<el-breadcrumb-item>案例管理</el-breadcrumb-item>
		<el-breadcrumb-item>案例分类</el-breadcrumb-item>
	</el-breadcrumb>

	<!-- 卡片视图区域 -->
	<el-card>
		<!-- 搜索区域 -->
		<el-form v-if="search.status">
			<el-row :gutter="50">
				<template v-for="(item,index) in search.list" :key="item.index">
					<el-col v-if="search.list[index].search==true" :span="7">
						<el-form-item :label="item.name" v-if="item.type=='text'">
							<el-input size="small" :placeholder="item.name" v-model="search.list[index].value" clearable
								@clear="getTableData()">
							</el-input>
						</el-form-item>

						<el-form-item :label="item.name" v-if="item.type=='time'">
							<el-date-picker size="small" v-model="search.list[index].value" type="daterange"
								:shortcuts="shortcuts" range-separator="至" start-placeholder="开始日期"
								end-placeholder="结束日期" unlink-panels format="YYYY-MM-DD" value-format="YYYY/MM/DD">
							</el-date-picker>
						</el-form-item>
						<el-form-item :label="item.name" v-if="item.type=='select'">
							<el-select size="small" v-model="search.list[index].value"
								@change="searchSelect(search.list[index].value)" placeholder="请选择" clearable>
								<el-option v-for="vo in options" :key="vo.value" :label="vo.label" :value="vo.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</template>
				<el-col :span="4">
					<el-form-item>
						<el-button size="small" @click="getTableData(true)" icon="el-icon-search">搜索</el-button>
<!-- 						<el-button size="small" icon="el-icon-search" @click="getTableData(false)">重置</el-button> -->
					</el-form-item>
				</el-col>

			</el-row>
		</el-form>
		<!-- 搜索区域end -->
		<el-row :gutter="8">
			<el-col :span="4">
				<!-- 新增表单区域 -->
				<el-dialog title="添加" v-model="dialogVisible" width="30%" @close="addDialogClosed()">
					<!-- 内容主体区域 -->
					<el-form :model="addForm" ref="addFormRef" label-width="100px">
						<div v-for="(item,index) in search.list" :key="index">
							<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
								:prop="index" v-if="search.list[index].type=='text'">
								<el-input v-model="addForm[index]"></el-input>
							</el-form-item>
							
							<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
								:prop="index" v-if="search.list[index].type == 'number'">
								<el-input type="number" v-model="addForm[index]"
									:placeholder="search.list[index].tisp"></el-input>
							</el-form-item>
							
							
							<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
								:prop="index" v-if="search.list[index].type=='tree'">
								<template #default="">
									<el-cascader v-model="search.list[index].value" clearable :placeholder="'设置一级类目可留空'"
										:options="tableData" :props="propMenu" @change="changeMenu"
										:show-all-levels="false"></el-cascader>
								</template>
							</el-form-item>
							
							
							<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
								:prop="index" v-if="search.list[index].type=='select'">
								<template #default="">
									<el-radio v-model="addForm[index]" :label="items.value"
										v-for="(items,i) in search.list[index].actives" :key="i">
										{{items.name}}
									</el-radio>
								</template>
							</el-form-item>
						</div>
					</el-form>
					<!-- 底部区域 -->
					<template #footer>
						<span class="dialog-footer">
							<el-button @click="dialogVisible=false">取 消</el-button>
							<el-button type="primary" @click="addForms()">确 定</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 编辑模态框区域 -->
				<el-dialog title="编辑" v-model="editDealogVisible" @close="edotDialogClosed()" width="30%">
					<el-form :model="editForm" ref="editFormRef" label-width="100px" v-for="(item,index) in search.list"
						:key="item.name">
						<div v-if="search.list[index].modal==true">
							<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
								:prop="index" v-if="search.list[index].type == 'text'">
								<el-input v-model="editForm[index]" :placeholder="search.list[index].tisp"></el-input>
								<!-- <label class="tisp">{{search.list[index].tisp}}</label> -->
							</el-form-item>

<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
								:prop="index" v-if="search.list[index].type == 'number'">
								<el-input type="number" v-model="editForm[index]"
									:placeholder="search.list[index].tisp"></el-input>
							</el-form-item>
							
							<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
								:prop="index" v-if="search.list[index].type=='tree'">
								<template #default="">
									<el-cascader v-model="search.list[index].value" clearable :placeholder="'默认不修改'"
										:options="tableData" :props="propMenu" @change="changeMenu"
										:show-all-levels="false"></el-cascader>
								</template>
							</el-form-item>


							<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
								:prop="index" v-if="search.list[index].type=='select'">
								<template #default="">
									<div v-for="items in search.list[index].actives" :key="items">
										<el-radio v-model="editForm[index]" :label="!items.value">
											{{items.name}}
										</el-radio>
									</div>
								</template>
							</el-form-item>
						</div>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button @click="editDealogVisible = false">取 消</el-button>
							<el-button type="primary" @click="tableEditData()">确 定</el-button>
						</span>
					</template>
				</el-dialog>
			</el-col>
		</el-row>
		<!-- table输出视图 -->
		<el-button :model="addForm" type="primary" @click="dialogVisible = true">添加</el-button>
		<el-button type="danger" @click="delect()">删除</el-button>
		<!-- <el-button type="warning" @click="delect()">导出</el-button> -->
		<!-- 	<el-table :data="tableData" @selection-change="tableSelect" row-key="id" :load="load" border stripe lazy :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"> -->
		<el-table  v-if="tableData.length>0" :data="tableData" style="width: 100%" @selection-change="tableSelect" row-key="id" border lazy
			:load="load" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
			<el-table-column type="selection" width="55">
			</el-table-column>
			<el-table-column type="index" :index='indexMethod' label="编号"></el-table-column>
			<el-table-column v-for="(items,i) in tableDataTemp" :key="i" :prop="i" :label="items.name">
				<template #header v-if="items.show==true">
					<span>
						{{items.name}}
					</span>
				</template>
				<template #default="scope">
					<span v-if="items.type=='select'">
						<el-switch v-model="scope.row.[i]" @change="switchChange(scope.row)" inactive-color="#c0c0c0"
							active-color="#724447" :active-value="0" :inactive-value="1">
						</el-switch>
					</span>
					<span v-else>
						<span v-if="items.show==true">
							{{scope.row[i]}}
						</span>
					</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="180px">
				<template #default="scope">
					<el-button type="primary" icon="el-icon-edit" size="mini"
						@click="showEditDialog(scope.$index, scope.row)">
					</el-button>
					<el-button type="danger" icon="el-icon-delete" size="mini" @click="removrRow(scope.row.id)">
					</el-button>
					<el-button type="danger" icon="el-icon-s-help" size="mini" @click="setTop(scope.row)"
						v-if="scope.row.pid!=0">
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页区域 -->
		<!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="queryInfo.pagenum" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pagesize"
			layout="total, sizes, prev, pager, next, jumper" :total="total">
		</el-pagination> -->
	</el-card>
	<el-dialog title="权限" v-model="treeDialogVisible" width="30%" @close="treeDialogClosed()">

		<el-card>
			<el-tree :data="treeData" show-checkbox node-key="id" default-expand-all highlight-current ref="tree"
				:props="defaultProps">
			</el-tree>

		</el-card>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="treeDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="getCheckedKeys()">确 定</el-button>
			</span>
		</template>
	</el-dialog>
</template>
<script>
	import {
		getCategory,
		categoryEdit,
		categoryAdd,
		categoryDel
	} from '../../api/cases.js'
	export default {
		data() {
			return {
				propMenu: {
					label: 'name',
					value: 'id',
					children: 'children',
					checkStrictly: true,
					lazy: true,
					async lazyLoad(node, resolve) {
						if(node.data.id!=undefined){
							let res = await getCategory({
								pid: node.data.id,
							});
							resolve(res.data.list);
						}else{
							console.log('node:', node.data.id);
						}
						
					}
				},
				defaultProps: {
					children: 'children',
					label: 'title',
				},
				treeData: [],
				options: [{
					value: false,
					label: '正常'
				}, {
					value: true,
					label: '隐藏'
				}],
				shortcuts: [{
					text: '最近一周',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						return [start, end]
					}
				}, {
					text: '最近一个月',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						return [start, end]
					}
				}, {
					text: '最近三个月',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						return [start, end]
					}
				}],
				//获取表格参数对象
				queryInfo: {
					query: {},
					//当前的页面
					pagenum: 1,
					//当前每页显示多少条数据
					pagesize: 10000,
				},
				//接口表格数据
				tableData: [],
				//本地表格临时数据
				tableDataTemp: {},
				//选中的row
				tableRow: [],

				//支持搜索的字段
				search: {
					status: true, //开启表单搜索
					list: {
						pid: {
							show: false, //表格是否显示字段
							search: false,
							modal: true,
							type: 'tree', //列类型,决定了搜索和显示的样式
							name: '父级栏目', //列名称
							value: '', //当前搜索情况下的值
							//验证规则
							rules: []
						},
						name: {
							show: true, //表格是否显示字段
							search: true,
							modal: true,
							type: 'text', //列类型,决定了搜索和显示的样式
							name: '名 称', //列名称
							value: '', //当前搜索情况下的值
							//验证规则
							rules: [{
									required: true,
									message: '请输入分组名称',
									trigger: 'blur',
								},
								{
									min: 2,
									max: 20,
									message: '长度在 2 到 10 个字符',
									trigger: 'blur',
								},
							]
						},
						sort: {
							show: true, //表格是否显示字段
							search: false,
							modal: true,
							type: 'number', //列类型,决定了搜索和显示的样式
							name: '排 序', //列名称
							value: 0, //当前搜索情况下的值
							//验证规则
							rules: []
						},
						create_time: {
							show: true, //表格是否显示字段
							search: false,
							modal: true,
							type: 'time',
							name: '添加日期',
							value: '',
							//验证规则
							rules: [{
									required: true,
									message: '请输入日期',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},
					}

				},
				//状态
				total: 0,

				//模态框新增状态
				dialogVisible: false,
				//模态框编辑状态
				editDealogVisible: false,
				//当前编辑对象
				editForm: [],
				//表单新增对象
				addForm: {},
				//表单编辑对象
				editRow: {},
				//获取父级ID类目
				pid: 0,
			}
		},
		created() {
			this.initTable();
			this.getTableData(false);
		},
		methods: {
			/**
			 * 菜单改变父级
			 */
			changeMenu(e) {
				if (e == null) {
					this.search.list.pid.value = 0;
				} else {
					let arr = JSON.parse(JSON.stringify(e));
					this.search.list.pid.value = arr.pop();
					console.log('当前值：', this.search.list.pid.value)
				}

			},
			/**
			 * 初始化表格数据
			 * 为配合动态渲染表格列，需生成tableDataTemp渲染
			 */
			initTable() {
				let _this = this;
				Object.keys(this.search.list).forEach(function(key) {
					if (_this.search.list[key].show == true) {
						_this.tableDataTemp[key] = _this.search.list[key];
					}
				});
			},
			indexMethod(index) {
				let curpage = this.queryInfo.pagenum; //单前页码，具体看组件取值
				let limitpage = this.queryInfo.pagesize; //每页条数，具体是组件取值
				return (index + 1) + (curpage - 1) * limitpage;
			},
			searchSelect(value) {
				console.log('您下拉选择了！', value);
				this.search.list.status.value = value;
				console.log('最新状态', this.search.list.status.value);
			},
			/**
			 * 加载接口数据
			 * value 是否搜索
			 */
			async getTableData(value) {
				let d = {};
				let datas = {};
				if (value) {
					//过滤筛选条件
					Object.keys(this.search.list).forEach((key) => {
						if (this.search.status == true) {
							if (this.search.list[key].value != '' && this.search.list[key].search==true) {
								//console.log(key+'资料',this.search.list[key].value);
								d[key] = {
									'value': this.search.list[key].value,
									'type': this.search.list[key].type,
								}
							}
						}
					});
					datas = {
						filter: JSON.stringify(d),
						page: this.queryInfo.pagenum,
						limit: this.queryInfo.pagesize
					}
				} else {
					datas = {
						pid: this.pid,
						page: this.queryInfo.pagenum,
						limit: this.queryInfo.pagesize
					}
					console.log('来这里了')
				}
				this.tableData = []
				let res = await getCategory(datas);
				this.tableData = res.data.list
				this.total = res.data.pagination.total
			},
			/**
			 * @param {Object} val
			 * 监听pagesize改变事件
			 */
			handleSizeChange(val) {
				this.queryInfo.pagesize = val
				this.getTableData()
			},
			/**
			 * @param {Object} val
			 * 监听页码值改变事件
			 */
			handleCurrentChange(val) {
				this.queryInfo.pagenum = val
				this.getTableData()
			},
			/**
			 * 设置类目为顶级
			 * @param {Object} id
			 */
			async setTop(row) {
				const confirmResult = await this.$confirm(
					"是否设置【"+row.name+"】为顶级类目, 是否继续?",
					'提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					}
				).catch((err) => err)
				if (confirmResult != 'confirm') {
					//取消操作
				} else {
					await categoryEdit({
						id: row.id,
						pid: 0,
						name: row.name,
					});
					this.pid = 0;
					this.getTableData(false);
					return this.$message.success('类目置顶成功');
				
				}
				
			},
			/**
			 * @param {Object} row
			 * 监听swit开关的改变状态
			 */
			async switchChange(row) {
				console.log(row.status);
				await categoryEdit({
					id: row.id,
					status: row.status,
					name: row.name,
				});
				return this.$message.success('切换成功');
			},
			//监听添加用户对话框的关闭事件，关闭清空表单数据
			addDialogClosed() {
				this.$refs.addFormRef.resetFields()
			},
			/**
			 * 表单新增
			 */
			async addForms() {
				this.$refs.addFormRef.validate(async (valid) => {
					console.log(valid);
					if (valid) {
						this.addForm.pid = this.search.list.pid.value;
						console.log('验证通过',this.addForm);
						await categoryAdd(this.addForm);
						this.pid = 0;
						this.getTableData(false);
						this.dialogVisible = false;
						this.$message.success('新增成功')
					} else {
						console.log('验证不通过');
						return false;
					}
				})
			},
			/**
			 * 表单模态框编辑
			 */
			showEditDialog(index, row) {
				this.search.list.pid.value = 0;
				this.editForm = JSON.parse(JSON.stringify(row));
				this.editDealogVisible = true;
			},
			/**
			 * 关闭表单编辑模态框
			 */
			edotDialogClosed() {
				this.$refs.editFormRef.resetFields()
			},
			/**
			 * 表单编辑
			 */
			tableEditData() {
				//开始实现你的伟大逻辑...
				//console.log('编辑的数据', JSON.stringify(this.editForm));
				this.$refs.editFormRef.validate(async (valid) => {
					if (valid) {
						if (this.search.list.pid.value != 0) {
							//只有被选择新类目后再更新父级
							this.editForm.pid = this.search.list.pid.value;
						}
						await categoryEdit(
							this.editForm
						);
						this.editDealogVisible = false;
						this.pid = 0;
						this.tableData = [];
						this.getTableData(false);
						return this.$message.success('编辑成功');
					} else {
						console.log('验证不通过');
						return false;
					}

				})
			},

			/**
			 * 删除选中行
			 * @param {Object} id
			 */
			async removrRow(id) {
				//弹框提示
				const confirmResult = await this.$confirm(
					'此操作将永久删除该数据, 是否继续?',
					'提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					}
				).catch((err) => err)
				if (confirmResult != 'confirm') {
					//取消操作
				} else {
					await categoryDel({
						ids:[id]
					});
					this.pid = 0;
					this.getTableData(false);
					this.$message.success('删除成功')

				}
			},
			/**
			 * 删除当前行
			 */
			async delect() {
				if (this.tableRow.length <= 0) {
					return this.$message.error("请选择删除行")
				} else {
					//弹框提示
					const confirmResult = await this.$confirm(
						'此操作将永久删除选中数据, 是否继续?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					).catch((err) => err)
					if (confirmResult != 'confirm') {
						//取消操作
					} else {
						await categoryDel({
							ids: this.tableRow
						});
						this.pid = 0;
						this.getTableData(false);
						this.$message.success('全部删除成功');	
					}
					
				}
			
			},
			/**
			 * 选项改变触发事件
			 * @param {Object} row
			 */
			tableSelect(row) {
				this.tableRow = [];
				Object.keys(row).forEach((key) => {
					this.tableRow[key] = row[key].id;
					//console.log('选中项ID',this.tableRow[key]);
				});

			},
			/**
			 * 懒加载触发
			 * @param {Object} tree 获取点击展开行数据
			 * @param {Object} treeNode
			 * @param {Object} resolve
			 */
			async load(tree, treeNode, resolve) {
				this.pid = tree.id;
				//let data = await this.getTableData(false);
				const res = await getCategory({
					pid: this.pid
				});
				resolve(res.data.list)
			},
		},
	}
</script>
<style lang="less" scoped>
	.el-pagination {
		margin-top: 15px;
	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.tisp {
		color: orangered;
		font-size: 12px;
	}
</style>
