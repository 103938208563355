import {
	http
} from '../http.js'

/**
 * 管理员列表
 * @param {Object} option
 */
export function index(option) {
	return http('admin/admin/index', 'post', option)
}
/**
 * 管理员编辑
 * @param {Object} option
 */
export function edit(option) {
	return http('admin/admin/edit', 'post', option)
}

/**
 * 管理员新增
 * @param {Object} option
 */
export function add(option) {
	return http('admin/admin/add', 'post', option)
}
/**
 * 反馈列表
 * @param {Object} option
 */
export function advise(option) {
	return http('admin/advise/index', 'post', option)
}
