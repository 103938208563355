import {
	http
} from '../http.js'
/**
 * 获取列表
 * @param {Object} option
 */
export function watchList(option) {
	return http('admin/watch/index', 'post', option)
}
/**
 * 编辑手环
 * @param {Object} option
 */
export function watchEdit(option) {
	return http('admin/watch/edit', 'post', option)
}
/**
 * 新增手环
 * @param {Object} option
 */
export function watchAdd(option) {
	return http('admin/watch/add', 'post', option)
}
/**
 * 删除手环
 * @param {Object} option
 */
export function delect(option) {
	return http('admin/watch/delect', 'post', option)
}
