<template>
	<!-- 面包屑导航 -->
	<el-breadcrumb>
		<el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
		<el-breadcrumb-item>药品管理</el-breadcrumb-item>
		<el-breadcrumb-item :to="{ path: 'index' }">药品列表</el-breadcrumb-item>
	</el-breadcrumb>

	<!-- 卡片视图区域 -->
	<el-card>
		<!-- 搜索区域 -->
		<el-form v-if="search.status">
			<el-row :gutter="50">
				<el-col :span="6" v-for="(item,index) in search.list" :key="item.index">
					<el-form-item :label="item.name" v-if="item.type=='text'">
						<el-input size="small" :placeholder="item.name" v-model="search.list[index].value" clearable
							@clear="getTableData()">
						</el-input>
					</el-form-item>
					<el-form-item :label="item.name" v-if="item.type=='time'">
						<el-date-picker size="small" v-model="search.list[index].value" type="daterange"
							:shortcuts="shortcuts" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
							unlink-panels format="YYYY-MM-DD" value-format="YYYY/MM/DD">
						</el-date-picker>
					</el-form-item>
					<el-form-item :label="item.name" v-if="item.type=='select'">
						<el-select size="small" v-model="search.list[index].value" placeholder="请选择">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="4">
					<el-form-item>
						<el-button size="small" @click="getTableData(true)" icon="el-icon-search">搜索</el-button>
						<el-button size="small" icon="el-icon-search">重置</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<!-- 搜索区域end -->
		<!-- table区域 -->
		<el-button :model="ruleForm" type="primary" @click="addGoods()">添加</el-button>
		<el-button type="danger" @click="delect()">删除</el-button>
		<!-- <el-button type="warning" @click="delect()">导出</el-button> -->
		<el-table :data="tableData" @selection-change="tableSelect" border stripe>
			<el-table-column type="selection" width="55">
			</el-table-column>
			<el-table-column type="index" :index='indexMethod' label="编号"></el-table-column>
			<el-table-column v-for="(items,i) in search.list" :key="i" :prop="i" :label="search.list[i].name">
				<template #default="scope">
					<div v-if="items.type=='array'">
						<el-tag type="info" v-for="arr in scope.row[i]" :key="arr.id">
							{{arr.cate_name}}
						</el-tag>
					</div>
					<div v-else-if="search.list.[i].type=='select'">
						<el-switch v-model="scope.row[i]" @change="switchChange(scope.row)" inactive-color="#c0c0c0"
							active-color="#724447" :active-value="0" :inactive-value="1">
						</el-switch>
					</div>
					<div v-else>
						{{scope.row.[i]}}
					</div>
				</template>
				
			</el-table-column>
			<el-table-column label="操作" width="180px">
				<template #default="scope">
					<el-button type="primary" icon="el-icon-edit" size="mini"
						@click="showEditDialog(scope.$index, scope.row)">
					</el-button>
					<el-button type="danger" icon="el-icon-delete" size="mini" @click="removrRow(scope.row.id)">
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页区域 -->
		<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="queryInfo.pagenum" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pagesize"
			layout="total, sizes, prev, pager, next, jumper" :total="total">
		</el-pagination>
	</el-card>
</template>
<script>
	import {
		index,
		edit,
		details,
		delect
	} from "../../api/goods.js"
	export default {
		data() {
			return {

				options: [{
					value: false,
					label: '正常'
				}, {
					value: true,
					label: '隐藏'
				}],
				shortcuts: [{
					text: '最近一周',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						return [start, end]
					}
				}, {
					text: '最近一个月',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						return [start, end]
					}
				}, {
					text: '最近三个月',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						return [start, end]
					}
				}],
				//获取表格参数对象
				queryInfo: {
					query: {},
					//当前的页面
					pagenum: 1,
					//当前每页显示多少条数据
					pagesize: 10,
				},
				//表格数据
				tableData: [],
				//选中的row
				tableRow: [],
				//支持搜索的字段
				search: {
					status: true, //开启表单搜索
					list: {
						goods_name: {
							search: true, //开启字段搜索
							type: 'text', //列类型,决定了搜索和显示的样式
							name: '药品名称', //列名称
							value: '', //当前搜索情况下的值
							//验证规则
							rules: [{
									required: true,
									message: '请输入药品名称',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},
						category: {
							search: true, //开启字段搜索
							type: 'array',
							name: '所属分组',
							value: '',
							//验证规则
							rules: [{
									required: true,
									message: '请输入日期',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},
						update_time: {
							search: true, //开启字段搜索
							type: 'time',
							name: '添加日期',
							value: '',
							//验证规则
							rules: [{
									required: true,
									message: '请输入日期',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},
						is_sale: {
							search: true, //开启字段搜索
							type: 'select',
							//select模型下才需要定义 自定义可以选择的值
							actives: [{
								name: '隐藏',
								value: false,
							}, {
								name: '显示',
								value: true,
							}],
							name: '状态',
							value: '',
							//验证规则
							rules: [{
								required: true,
								message: '状态未选择',
								trigger: 'blur',
							}]
						}
					}

				},
				//状态
				total: 0,
				//当前编辑对象
				editForm: {},
				//表单新增对象
				ruleForm: {},
				editRow: ''
			}
		},
		created() {
			this.getTableData(false)
		},
		methods: {
			indexMethod(index) {
				let curpage = this.queryInfo.pagenum; //单前页码，具体看组件取值
				let limitpage = this.queryInfo.pagesize; //每页条数，具体是组件取值
				return (index + 1) + (curpage - 1) * limitpage;
			},
			async getTableData(value) {
				let d = {};
				if (value) {
					Object.keys(this.search.list).forEach((key) => {
						if (this.search.status == true) {
							console.log('加载状态', this.search.list[key]);
							if (this.search.list[key].value != '' && this.search.list[key].search == true) {
								console.log(key + '资料', this.search.list[key].value);
								d[key] = {
									'value': this.search.list[key].value,
									'type': this.search.list[key].type,
								}
							}
						}
					});

					//console.log('加载状态',JSON.parse(JSON.stringify(this.search.list)));
				}

				let res = await index({
					filter: JSON.stringify(d),
					page: this.queryInfo.pagenum,
					limit: this.queryInfo.pagesize
				});
				this.tableData = res.data.list
				this.total = res.data.pagination.total
			},
			/**
			 * @param {Object} val
			 * 监听pagesize改变事件
			 */
			handleSizeChange(val) {
				this.queryInfo.pagesize = val
				this.getTableData()
			},
			/**
			 * @param {Object} val
			 * 监听页码值改变事件
			 */
			handleCurrentChange(val) {
				this.queryInfo.pagenum = val
				this.getTableData()
			},
			/**
			 * @param {Object} row
			 * 监听swit开关的改变状态
			 */
			async switchChange(row) {
				//开始实现你的伟大逻辑...
				if (row.goods_name) {
					console.log('switch切换成功', row.is_sale);
					await edit({
						id: row.id,
						is_sale: row.is_sale,
						goods_name: row.goods_name
					});
					return this.$message.success('切换成功');
				}
			},
			//监听添加用户对话框的关闭事件，关闭清空表单数据
			addDialogClosed() {
				console.log('关闭了窗口!');
				this.$refs.ruleFormRef.resetFields()
			},
			/**
			 * 添加药品
			 */
			addGoods(){
				this.$router.push({
					path: './add'
					})
			},
			/**
			 * 药品编辑
			 */
			async showEditDialog(index, row) {
				this.$router.push({
					path: './edit',
					query: {
						ids: row.id
					}
				})
				this.editRow = index;
				console.log('编辑的数据', this.editRow);
				let res = await details({
					id: row.id
				});
				this.editForm = res.data;
			},
			/**
			 * 删除选中行
			 * @param {Object} id
			 */
			async removrRow(id) {
				//弹框提示
				const confirmResult = await this.$confirm(
					'此操作将永久删除该用户, 是否继续?',
					'提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					}
				).catch((err) => err)
				if (confirmResult != 'confirm') {
					//取消操作
				} else {
					await delect({
						ids: [id]
					});
					this.getTableData(false)
					this.$message.success('删除成功,ID:' + id)

				}
			},
			/**
			 * 选项改变触发事件
			 * @param {Object} row
			 */
			tableSelect(row) {
				this.tableRow = [];
				Object.keys(row).forEach((key) => {
					this.tableRow[key] = row[key].id;
					//console.log(this.tableRow[key]);
				});

			},
			/**
			 * 删除选中行
			 */
			async delect() {
				if (this.tableRow.length <= 0) {
					return this.$message.error("请选择删除行")
				}
				//弹框提示
				const confirmResult = await this.$confirm(
					'此操作将永久删除该用户, 是否继续?',
					'提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					}
				).catch((err) => err)
				if (confirmResult != 'confirm') {
					//取消操作
				} else {

					await delect({
						ids: this.tableRow
					});
					this.getTableData(false)
					console.log('删除成功ids', JSON.stringify(this.tableRow));

				}


			},
		},
	}
</script>
<style lang="less" scoped>
	.el-pagination {
		margin-top: 15px;
	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
</style>
