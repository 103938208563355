<template>
	<el-row class="home_container">
		<el-container>
			<!-- 头部区域 -->

			<el-container>
				<!-- 侧边栏 -->
				<el-aside :width="isCollapse ? '64px' : '200px'">
					<el-header>
						<div class="logo">
							<img src="../assets/logo.png">
						</div>
					</el-header>
					<!-- 侧边栏菜单区域 -->
					<el-menu background-color="#f4f6f8" text-color="#86888a" active-text-color="#724447"
						:unique-opened="true" :collapse="isCollapse" :collapse-transition="false" router
						:default-active='activerule'>
						<!-- 一级菜单 -->
						<el-submenu :index="item.id + ''" v-for="item in menulist" :key="item.id">
							<!-- 一级菜单模板区域 -->
							<template #title>
								<!-- 图标 -->
								<i :class="item.icon"></i>
								<!-- 文本 -->
								<span>{{ item.title }}</span>
							</template>
							<!-- 二级菜单 -->
							<el-menu-item :index="'/'+subitem.rule" v-for="subitem in item.children" :key="subitem.id"
								@click="saveNavState('/'+subitem.rule)">
								<template #title>
									<!-- 图标 -->
									<i :class="subitem.icon"></i>
									<!-- 文本 -->
									<span>{{ subitem.title }}</span>
								</template>
							</el-menu-item>
						</el-submenu>
					</el-menu>
				</el-aside>
				<!-- 右侧内容主体 -->
				<el-main>
					<el-header>
						<div class="toggle_button" @click="toggleCollapse()">
							<i :class="[isCollapse?'el-icon-s-fold':'el-icon-s-unfold ']"></i>
						</div>
						<div class="header-menu">
							<div>
								<!-- <el-button>预留位置</el-button>
								<el-button>预留位置</el-button> -->
							</div>
							<div>
								<el-button type="danger" @click="dialogVisible = true">修改密码</el-button>
								<el-button type="danger" @click="logout()">退出</el-button>
							</div>
						</div>
					</el-header>

					<!-- 路由占位符 -->
					<div class="main">
						<router-view></router-view>
					</div>
				</el-main>
			</el-container>
		</el-container>
	</el-row>

	<el-row :gutter="8">
		<el-col :span="4">
			<!-- 修改密码区域 -->
			<el-dialog title="修改密码" v-model="dialogVisible" width="20%" @close="editDialogClosed()">
				<!-- 内容主体区域 -->
				<el-form :model="editForm" :rules="editRules" ref="editForm" label-width="100px">
					<el-form-item label="旧密码" prop="password">
						<el-input v-model="editForm.password">
						</el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="new_password">
						<el-input v-model="editForm.new_password"></el-input>
					</el-form-item>
					<el-form-item label="确认新密码" prop="confirm_password">
						<el-input v-model="editForm.confirm_password"></el-input>
					</el-form-item>
				</el-form>
				<!-- 底部区域 -->
				<template #footer>
					<span class="dialog-footer">
						<el-button @click="dialogVisible = false">取 消</el-button>
						<el-button type="primary" @click="changePassword()">确 定</el-button>
					</span>
				</template>
			</el-dialog>
		</el-col>
	</el-row>


</template>
<script>
	import {
		menus,
		changePassword
	} from "../api/index.js"
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	export default {
		data() {
			return {
				editForm: {
					password: '',
					new_password: '',
					confirm_password: '',
				},
				editRules: {
					password: [{
						required: true,
						message: '旧密码不能为空',
						trigger: 'blur'
					}],
					new_password: [{
						required: true,
						message: '请输入新密码',
						trigger: 'blur'
					}],
					confirm_password: [{
						required: true,
						message: '请再次输入新密码',
						trigger: 'blur'
					}],
				},
				dialogVisible: false,
				menulist: [],
				isCollapse: false,
				//被激活链接地址
				activerule: '',
			}
		},
		//生命周期函数
		created() {

			this.getMenuList(),
				//取出被激活链接地址
				this.activerule = window.sessionStorage.getItem('activerule')
		},
		methods: {

			/**
			 * 关闭 清空表单数据
			 */
			editDialogClosed() {
				console.log('关闭了窗口!');
				this.$refs.editForm.resetFields()
			},
			/**
			 * 修改密码
			 */
			changePassword() {
				console.log(this.editForm);
				this.$refs.editForm.validate(async (valid) => {
					if (valid) {
						ElMessageBox.confirm(
								'修改密码后你将重新登录',
								'是否确定修改密码?',
								'Warning', {
									confirmButtonText: '确 定',
									cancelButtonText: '取 消',
									type: 'warning',
								}
							)
							.then(async () => {
								await changePassword(this.editForm);
							})
							.catch(() => {

							})
					}
				})

			},
			logout() {
				//退出登录
				//清空token，跳回login界面
				window.sessionStorage.clear()
				ElMessage.success({
					//弹框提示
					message: '已退出',
					type: 'success',
				})
				this.$router.push('/login')
			},
			//获取所有的菜单
			async getMenuList() {
				// let res = [{
				// 	"id": 125,
				// 	"title": "文章管理",
				// 	"rule": "article",
				// 	"children": [{
				// 		"id": 110,
				// 		"title": "文章列表",
				// 		"rule": "article/index",
				// 		"children": [],
				// 	}, {
				// 		"id": 110,
				// 		"title": "文章新增",
				// 		"rule": "article/add",
				// 		"children": [],

				// 	}],	
				// },
				// {
				// 	"id": 125,
				// 	"title": "药品管理",
				// 	"rule": "goods",
				// 	"children": [{
				// 		"id": 110,
				// 		"title": "药品列表",
				// 		"rule": "goods/index",
				// 		"children": [],
				// 	},{
				// 		"id": 110,
				// 		"title": "新增药品",
				// 		"rule": "goods/add",
				// 		"children": [],
				// 	}],	
				// },
				// {
				// 	"id": 125,
				// 	"title": "采集管理",
				// 	"rule": "article",
				// 	"children": [{
				// 		"id": 120,
				// 		"title": "采集数据",
				// 		"rule": "cs",
				// 		"children": [],
				// 	},{
				// 		"id": 120,
				// 		"title": "编辑数据",
				// 		"rule": "reptile/index",
				// 		"children": [],
				// 	}]
				// }]
				//this.menulist = res;
				let res = await menus();
				this.menulist = res.data.list;
			},
			toggleCollapse() {
				this.isCollapse = !this.isCollapse
			},
			//记录激活链接地址
			saveNavState(activerule) {
				window.sessionStorage.setItem('activerule', activerule)
				this.activerule = activerule
			}
		},
	}
</script>
<style lang="less" scoped>
	.home_container {
		height: 100%;
	}



	.el-header {
		display: flex;
		align-items: center;
		padding-left: 15px;
		color: #fff;
		font-size: 20px;
		background-color: #724447;

		>div {
			display: flex;
			align-items: center;

			img {
				width: 165px;
				height: 40px;
			}

			span {
				font-size: 13px;
			}
		}
	}

	.header-menu {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	// 侧边栏
	.el-aside {
		background-color: #323642;

		.el-menu {
			border: 0;
		}
	}

	.toggle_button {
		text-align: center;
		line-height: 24px;
		color: #fbf6f9;
		cursor: pointer;
		letter-spacing: 0.2em;
		padding: 10px;
		font-size: 20px;
	}

	.toggle_button:hover {
		color: #724447;
		border-radius: 2px;
		background-color: #eaedf1;
	}

	.el-main {
		padding: 0px;
		background-color: #eaedf1;

		.el-header {}

		.main {
			padding: 20px;
		}
	}

	.el-aside {
		background-color: #f4f6f8;
	}
</style>
