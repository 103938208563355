import {
	http
} from '../http.js'

/**
 * 用户列表
 * @param {Object} option
 */
export function userList(option) {
	return http('admin/user/index', 'post', option)
}
/**
 * 用户编辑
 * @param {Object} option
 */
export function userEdit(option) {
	return http('admin/user/edit', 'post', option)
}
/**
 * 用户新增
 * @param {Object} option
 */
export function userAdd(option) {
	return http('admin/user/add', 'post', option)
}

/**
 * 获取待审核的医生记录
 */
export function doctorAuthList(option) {
	return http('admin/doctor/auth', 'post', option)
}
/**
 * 审核医生
 */
export function doctorAuthAdopt(option) {
	return http('admin/doctor/adopt', 'post', option)
}