<template>
	<!-- 面包屑导航 -->
	<div class="main">
		<el-breadcrumb>
			<el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>应用管理</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-container>
				<el-aside>
					<!-- table输出视图 -->
					<!-- <el-button :model="addForm" type="success" @click="dialogVisible = true">添加应用</el-button>
					<el-button type="danger" @click="delect()">删除应用</el-button> -->
					<!-- <el-button type="warning" @click="delect()">导出</el-button> -->
					<el-table :data="appData" @selection-change="tableSelect" @row-click="appClickRow" border stripe>
						<el-table-column type="selection">
						</el-table-column>
						<el-table-column type="index" :index='indexMethod' label="编号"></el-table-column>
						<el-table-column prop="name" label="名称" />
						<el-table-column label="操作">
							<template #default="scope">
								<el-button type="primary" icon="el-icon-edit" size="mini"
									@click="appShowEditDialog(scope.$index, scope.row)">
								</el-button>
								<!-- <el-button type="danger" icon="el-icon-delete" size="mini" @click="removrRow(scope.row.id)">
							</el-button> -->

							</template>
						</el-table-column>
					</el-table>
					<!-- 分页区域 -->
					<!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="queryInfo.pagenum" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination> -->

				</el-aside>
				<el-main>

					<!-- 搜索区域 -->
					<el-form v-if="search.status">
						<el-row :gutter="50">
							<template v-for="(item,index) in search.list" :key="item.index">
								<el-col v-if="search.list[index].search==true" :span="7">
									<el-form-item :label="item.name" v-if="item.type=='text'">
										<el-input size="small" :placeholder="item.name"
											v-model="search.list[index].value" clearable @clear="getTableData()">
										</el-input>
									</el-form-item>

									<el-form-item :label="item.name" v-if="item.type=='time'">
										<el-date-picker size="small" v-model="search.list[index].value" type="daterange"
											:shortcuts="shortcuts" range-separator="至" start-placeholder="开始日期"
											end-placeholder="结束日期" unlink-panels format="YYYY-MM-DD"
											value-format="YYYY/MM/DD">
										</el-date-picker>
									</el-form-item>
									<el-form-item :label="item.name" v-if="item.type=='select'">
										<el-select size="small" v-model="search.list[index].value"
											@change="searchSelect(search.list[index].value)" placeholder="请选择"
											clearable>
											<el-option v-for="vo in options" :key="vo.value" :label="vo.label"
												:value="vo.value">
											</el-option>
										</el-select>
									</el-form-item>
								</el-col>
							</template>
							<el-col :span="4">
								<el-form-item>
									<el-button size="small" @click="getTableData(true)" icon="el-icon-search">搜索
									</el-button>
									<el-button size="small" icon="el-icon-search">重置</el-button>
								</el-form-item>
							</el-col>

						</el-row>
					</el-form>
					<!-- 搜索区域end -->
					<el-row :gutter="8">
						<el-col :span="4">
							<!-- 新增表单区域 -->

							<el-dialog :title="tableLeft.name+'发布新版本'" v-model="dialogVisible" width="30%"
								@close="addDialogClosed()">
								<!-- 内容主体区域 -->
								<el-form :model="addForm" ref="addFormRef" label-width="100px"
									v-for="(item,index) in search.list" :key="index">
									<div v-if="search.list[index].modal==true">
										<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
											:prop="index" v-if="search.list[index].type=='text'">
											<el-input v-model="addForm[index]"></el-input>
										</el-form-item>

										<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
											:prop="index" v-if="search.list[index].type == 'textarea'">
											<el-input type="textarea" maxlength="250" rows="10" v-model="addForm[index]"
												:placeholder="search.list[index].tisp">
											</el-input>
											<!-- <label class="tisp">{{search.list[index].tisp}}</label> -->
										</el-form-item>

										<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
											:prop="index" v-if="search.list[index].type=='select'">
											<template #default="">
												<el-radio v-model="addForm[index]" :label="items.value"
													v-for="(items,i) in search.list[index].actives" :key="i">
													{{items.name}}
												</el-radio>
											</template>
										</el-form-item>

										<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
											:prop="index" v-if="search.list[index].type=='array'">
											<el-select v-model="addForm[index]" multiple placeholder="请选择角色">
												<el-option v-for="item in search.list[index].option" :key="item.id"
													:label="item.name" :value="item.id">
												</el-option>
											</el-select>
										</el-form-item>

										<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
											:prop="index" v-if="search.list[index].type=='file'">
											<el-upload class="upload-demo" ref='upload' :data="QiniuData"
												:action="serverPath" :show-file-list="false" :on-progress="onProgress"
												:on-success="onSuccessAdd" :before-upload="beforeUpload" :limit="1"
												:on-exceed="handleExceed">
												<el-button size="small" type="primary" :disabled="disabled">上传应用
												</el-button>
											</el-upload>
										</el-form-item>


									</div>
								</el-form>
								<!-- 底部区域 -->
								<template #footer>
									<span class="dialog-footer">
										<el-button @click="dialogVisible = false">取 消</el-button>
										<el-button type="primary" @click="addForms()">确 定</el-button>
									</span>
								</template>
							</el-dialog>
							<!-- APP编辑模态框区域 -->
							<el-dialog :title="tableLeft.name+' 应用编辑'" v-model="appEditDealogVisible"
								@close="edotDialogClosed()" width="30%">
								<el-form :model="editForm" ref="editFormRef" label-width="100px">
									<el-form-item label="名 称">
										<el-input v-model="editForm.name"></el-input>
									</el-form-item>
								</el-form>

								<template #footer>
									<span class="dialog-footer">
										<el-button @click="appEditDealogVisible = false">取 消</el-button>
										<el-button type="primary" @click="appTableEditData()">确 定</el-button>
									</span>
								</template>
							</el-dialog>
							<!-- 编辑模态框区域 -->
							<el-dialog :title="tableLeft.name+' 版本编辑'" v-model="editDealogVisible"
								@close="edotDialogClosed()" width="30%">
								<el-form :model="editForm" ref="editFormRef" label-width="100px"
									v-for="(item,index) in search.list" :key="item.name">
									<div v-if="search.list[index].modal==true">
										<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
											:prop="index" v-if="search.list[index].type == 'text'">
											<el-input v-model="editForm[index]" :placeholder="search.list[index].tisp">
											</el-input>
											<!-- <label class="tisp">{{search.list[index].tisp}}</label> -->
										</el-form-item>
										<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
											:prop="index" v-if="search.list[index].type == 'textarea'">
											<el-input type="textarea" maxlength="250" rows="10"
												v-model="editForm[index]" :placeholder="search.list[index].tisp">
											</el-input>
											<!-- <label class="tisp">{{search.list[index].tisp}}</label> -->
										</el-form-item>
										<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
											:prop="index" v-if="search.list[index].type=='select'">
											<template #default="">
												<div v-for="items in search.list[index].actives" :key="items">
													<el-radio v-model="editForm[index]" :label="items.value">
														{{items.name}}
													</el-radio>
												</div>
											</template>
										</el-form-item>

										<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
											:prop="index" v-if="search.list[index].type=='array'">
											<el-select v-model="editForm[index]" multiple placeholder="请选择角色">
												<el-option v-for="item in search.list[index].option" :key="item.id"
													:label="item.name" :value="item.id">
												</el-option>
											</el-select>
										</el-form-item>

										<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
											:prop="index" v-if="search.list[index].type=='number'">
											<el-input-number v-model="editForm[index]" :min="0" :max="120"
												@change="handleChange" />
										</el-form-item>

										<el-form-item :rules="search.list[index].rules" :label="search.list[index].name"
											:prop="index" v-if="search.list[index].type=='file'">
											<el-upload class="upload-demo" ref='upload' :data="QiniuData"
												:action="serverPath" :show-file-list="false" :on-progress="onProgress"
												:on-success="onSuccess" :before-upload="beforeUpload" :limit="1"
												:on-exceed="handleExceed">
												<el-button size="small" type="primary" :disabled="disabled">上传应用
												</el-button>
											</el-upload>
										</el-form-item>
									</div>
								</el-form>

								<template #footer>
									<span class="dialog-footer">
										<el-button @click="editDealogVisible = false">取 消</el-button>
										<el-button type="primary" @click="tableEditData()">确 定</el-button>
									</span>
								</template>
							</el-dialog>
						</el-col>
					</el-row>
					<!-- table输出视图 -->
					<el-button :model="addForm" type="primary" @click="showAddDialog()">发布新版本</el-button>
					<!-- <el-button type="danger" @click="delect()">禁 用</el-button> -->
					<!-- <el-button type="warning" @click="delect()">导出</el-button> -->
					<el-table :data="tableData" @selection-change="tableSelect" border stripe>
						<el-table-column type="selection" width="55">
						</el-table-column>
						<el-table-column type="index" :index='indexMethod' label="编号"></el-table-column>
						<el-table-column v-for="(items,i) in tableDataTemp" :key="i" :prop="i" :label="items.name">
							<template #header v-if="items.show==true">
								<span>
									{{items.name}}
								</span>
							</template>
							<template #default="scope">
								<div v-if="items.type=='array'">
									<el-tag type="info" v-for="arr in scope.row[i]" :key="arr.id">
										{{arr.name}}
									</el-tag>
								</div>
								<div v-else-if="items.type=='image'">
									<div class="block">
										<el-avatar shape="square" :size="50" :src="scope.row[i]"></el-avatar>
									</div>
								</div>
								<div v-else-if="items.type=='tag'">
									<div v-if="scope.row[i]==0">
										<el-tag type="danger">未认证</el-tag>
									</div>
									<div v-if="scope.row[i]==1">
										<el-tag type="success">已实名</el-tag>
									</div>
								</div>
								<div v-else-if="items.type=='select'">
									<el-switch v-model="scope.row[i]" @change="switchChange(scope.row)"
										active-color="#13ce66" inactive-color="#ff4949">
									</el-switch>
								</div>
								<div v-else>
									<span v-if="items.show==true">
										{{scope.row[i]}}
									</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="180px">
							<template #default="scope">
								<el-button type="primary" icon="el-icon-edit" size="mini"
									@click="showEditDialog(scope.$index, scope.row)">
								</el-button>
								<!-- <el-button type="danger" icon="el-icon-delete" size="mini" @click="removrRow(scope.row.id)">
								</el-button> -->

							</template>
						</el-table-column>
					</el-table>
					<!-- 分页区域 -->
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="queryInfo.pagenum" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>


				</el-main>
			</el-container>
		</el-card>
		<!-- 卡片视图区域 -->
	</div>
</template>
<script>
	import {
		qiniuToken,
		appList,
		version,
		versionEdit,
		versionAdd,
		appEdit
	} from '../../api/app.js'
	export default {
		data() {
			return {
				//上传资料
				disabled: false,
				imgPath: '', //七牛云外链
				serverPath: 'http://upload-z2.qiniup.com',
				QiniuData: {
					key: "wangrub13", //图片名字处理
					token: "jgDnmrXSo5OrK1ebw3XbHlnQtd5T2G4lL5uq6dBk:U6qikOm7bklI4UhCKhfGGX6Wx_U=:eyJzY29wZSI6ImRvY3Rvci1zeXMiLCJkZWFkbGluZSI6MTYzNjk2MTc3OH0=", //七牛云token
					data: {}
				},
				defaultProps: {
					children: 'children',
					label: 'title',
				},
				treeData: [],
				options: [{
					value: '1', //不加双引号，el-select标签v-model绑定不了1 
					label: '正常'
				}, {
					value: '0',
					label: '禁止'
				}],
				shortcuts: [{
					text: '最近一周',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						return [start, end]
					}
				}, {
					text: '最近一个月',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						return [start, end]
					}
				}, {
					text: '最近三个月',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						return [start, end]
					}
				}],
				//获取表格参数对象
				queryInfo: {
					query: {},
					//当前的页面
					pagenum: 1,
					//当前每页显示多少条数据
					pagesize: 10,
				},
				//接口表格数据
				tableData: [],
				//本地表格临时数据
				tableDataTemp: {},
				//选中的row
				tableRow: [],

				//支持搜索的字段
				search: {
					status: false, //开启表单搜索
					list: {
						name: {
							show: true, //表格是否显示字段
							search: true, //是否搜索字段
							modal: false, //开启模态框显示
							type: 'text', //列类型,决定了搜索和显示的样式
							name: 'APP', //列名称
							value: '', //当前搜索情况下的值
							//验证规则
							rules: [{
									required: true,
									message: '请输入账号',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},
						title: {
							show: true, //表格是否显示字段
							search: true, //是否搜索字段
							modal: true, //开启模态框显示
							type: 'text', //列类型,决定了搜索和显示的样式
							name: '标 题', //列名称
							value: '', //当前搜索情况下的值
							//验证规则
							rules: [{
									required: true,
									message: '请输入账号',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},
						message: {
							show: false, //表格是否显示字段
							search: true, //是否搜索字段
							modal: true, //开启模态框显示
							type: 'textarea', //列类型,决定了搜索和显示的样式
							name: '内 容', //列名称
							value: '', //当前搜索情况下的值
							//验证规则
							rules: [{
									required: true,
									message: '请输入账号',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},
						edition: {
							show: true, //表格是否显示字段
							search: true, //是否搜索字段
							modal: true, //开启模态框显示
							type: 'text', //列类型,决定了搜索和显示的样式
							name: '版本号', //列名称
							value: '', //当前搜索情况下的值
							//验证规则
							rules: [{
									required: true,
									message: '请输入账号',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},
						url: {
							show: true, //表格是否显示字段
							search: true, //是否搜索字段
							modal: true, //开启模态框显示
							type: 'text', //列类型,决定了搜索和显示的样式
							name: '下载地址', //列名称
							value: '', //当前搜索情况下的值
							//验证规则
							rules: [{
									required: true,
									message: '请输入账号',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},
						file: {
							show: false, //表格是否显示字段
							search: false, //是否搜索字段
							modal: true, //开启模态框显示
							type: 'file', //列类型,决定了搜索和显示的样式
							name: '上传应用', //列名称
						},
						create_time: {
							show: true, //表格是否显示字段
							search: true,
							modal: true,
							type: 'time',
							name: '更新时间',
							value: '',
							//验证规则
							rules: [{
									required: true,
									message: '请输入日期',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},
					}

				},
				//状态
				total: 0,
				//权限模态框状态
				treeDialogVisible: false,
				//模态框新增状态
				dialogVisible: false,
				//模态框编辑状态
				editDealogVisible: false,
				appEditDealogVisible: false,
				//当前编辑对象
				editForm: {},
				//当前新增对象
				addForm: {},
				editRow: {},
				//左侧表格数据
				app_id: '', //当前选中的左侧表id
				appData: [],
				tableLeft: {}, //当前选中的左则行
			}
		},
		created() {
			this.initTable();
			this.getAppList();
		},
		methods: {
			/**
			 * 获取七牛云上传Token
			 */
			async getQiniuToken() {
				let res = await qiniuToken({});
				this.QiniuData.key = res.data.key + '.apk'
				this.QiniuData.token = res.data.token
				this.imgPath = res.data.domain
			},
			/**
			 * 编辑文件上传成功钩子事件
			 */
			onSuccess(res) {
				console.log('上传成功', res)
				let docUrl = this.imgPath + '/' + res.key
				console.log('下载地址', docUrl)
				this.disabled = false
				this.editForm.url = docUrl
				this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
			},
			/**
			 * 编辑文件上传成功钩子事件
			 */
			onSuccessAdd(res) {
				console.log('上传成功', res)
				let docUrl = this.imgPath + '/' + res.key
				console.log('下载地址', docUrl)
				this.disabled = false
				this.addForm.url = docUrl
				this.$refs.upload.clearFiles(); //上传成功之后清除历史记录

			},
			/**
			 * 文件上传前钩子,获取到七牛云token
			 */
			beforeUpload() {
				this.getQiniuToken()
				console.log('获取上传token成功')
			},
			/**
			 * 文件上传中钩子
			 */
			onProgress(res) {
				console.log('文件上传中', res)
				this.disabled = true
			},
			/**
			 * 获取左侧数据
			 */
			async getAppList() {
				let res = await appList({
					limit: 10,
					page: 1
				});
				this.appData = res.data.list
				this.tableLeft = this.appData[0] //默认设置第一条是选中记录
				this.getTableData(false);
				console.log('应用数据', this.appData)
			},
			/**
			 * 初始化表格数据
			 * 为配合动态渲染表格列，需生成tableDataTemp渲染
			 */
			initTable() {
				let _this = this;
				Object.keys(this.search.list).forEach(function(key) {
					if (_this.search.list[key].show == true) {
						_this.tableDataTemp[key] = _this.search.list[key];
					}
				});
			},
			indexMethod(index) {
				let curpage = this.queryInfo.pagenum; //单前页码，具体看组件取值
				let limitpage = this.queryInfo.pagesize; //每页条数，具体是组件取值
				return (index + 1) + (curpage - 1) * limitpage;
			},
			searchSelect(value) {
				console.log('您下拉选择了！', value);
				this.search.list.status.value = value;
				console.log('最新状态', this.search.list.status.value);
			},
			/**
			 * 加载接口数据
			 * value 是否搜索
			 */
			async getTableData(value) {
				let d = {};
				if (value) {
					//过滤筛选条件
					Object.keys(this.search.list).forEach((key) => {
						if (this.search.status == true) {
							if (this.search.list[key].value != '') {
								//console.log(key+'资料',this.search.list[key].value);
								d[key] = {
									'value': this.search.list[key].value,
									'type': this.search.list[key].type,
								}
							}
						}
					});
				}
				this.app_id = this.app_id == '' ? this.appData[0].id : this.app_id;
				let res = await version({
					filter: JSON.stringify(d),
					page: this.queryInfo.pagenum,
					limit: this.queryInfo.pagesize,
					app_id: this.app_id

				});
				this.tableData = res.data.list
				this.total = res.data.pagination.total
			},
			/**
			 * @param {Object} val
			 * 监听pagesize改变事件
			 */
			handleSizeChange(val) {
				this.queryInfo.pagesize = val
				this.getTableData()
			},
			/**
			 * @param {Object} val
			 * 监听页码值改变事件
			 */
			handleCurrentChange(val) {
				this.queryInfo.pagenum = val
				this.getTableData()
			},
			/**
			 * @param {Object} row
			 * 监听swit开关的改变状态
			 */
			async switchChange(row) {
				//开始实现你的伟大逻辑...
				console.log('switch切换成功', row.status);
				return this.$message.success('切换成功');
			},
			//监听添加用户对话框的关闭事件，关闭清空表单数据
			addDialogClosed() {
				console.log('关闭了窗口!');
				this.$refs.addFormRef.resetFields()
			},
			/**
			 * 表单新增
			 */
			async addForms() {
				this.$refs.addFormRef.validate(async (valid) => {
					if (valid) {
						this.addForm.app_id = this.tableLeft.id
						await versionAdd(this.addForm);
						this.dialogVisible = false;
						this.getTableData(false);
						return this.$message.success('新增成功');
					}
				})
			},
			/**
			 * 返回一维数组某列
			 * @param {Object} name 需要返回的属性名
			 * @param {Object} arr 原始数据
			 */
			getParamValues(name, arr) {
				var ret = []
				for (var i = 0, len = arr.length; i < len; i++) {
					ret.push(arr[i][name])
				}
				return ret
			},
			/**
			 * 编辑表单模态框弹出
			 */
			showEditDialog(index, row) {
				console.log('编辑行数据', row);
				this.editDealogVisible = true;
				row.password = ''; //清空密码默认值
				this.editForm = JSON.parse(JSON.stringify(row));
				this.getQiniuToken()

			},
			/**
			 * app编辑表单模态框弹出
			 */
			appShowEditDialog(index, row) {
				console.log('编辑行数据', row);
				this.editForm = JSON.parse(JSON.stringify(row));
				this.appEditDealogVisible = true;
			},
			/**
			 * 表单模态框弹出
			 */
			showAddDialog() {
				this.addForm = {}
				console.log('新增行数据');
				this.dialogVisible = true;
				this.getQiniuToken()
			},
			/**
			 * 关闭表单编辑模态框
			 */
			edotDialogClosed() {
				this.$refs.editFormRef.resetFields()
			},
			/**
			 * 表单编辑
			 */
			tableEditData() {
				console.log('编辑的数据', JSON.stringify(this.editForm));
				this.$refs.editFormRef.validate(async (valid) => {
					if (valid) {
						await versionEdit(
							this.editForm
						);
						this.editDealogVisible = false;
						this.getTableData(false)
						return this.$message.success('编辑成功');
					} else {
						console.log('验证不通过');
						return false;
					}

				})
			},
			/**
			 * app表单编辑
			 */
			appTableEditData() {
				console.log('编辑的数据', JSON.stringify(this.editForm));
				this.$refs.editFormRef.validate(async (valid) => {
					if (valid) {
						await appEdit(
							this.editForm
						);
						this.appEditDealogVisible = false;
						this.getAppList()
						return this.$message.success('编辑成功');
					} else {
						console.log('验证不通过');
						return false;
					}

				})
			},
			/**
			 * 删除选中行
			 * @param {Object} id
			 */
			async removrRow(id) {
				//弹框提示
				const confirmResult = await this.$confirm(
					'此操作将永久删除该用户, 是否继续?',
					'提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					}
				).catch((err) => err)
				if (confirmResult != 'confirm') {
					//取消操作
				} else {
					this.$message.success('删除成功,ID:' + id)

				}
			},
			/**
			 * 左侧被点击
			 */
			appClickRow(row) {
				console.log("行被点击", row.id);
				this.app_id = row.id
				this.tableLeft = row
				this.getTableData(false);
				//改变右则视图
			},
			/**
			 * 选项改变触发事件
			 * @param {Object} row
			 */
			tableSelect(row) {
				console.log("选项发生改变", row);
				this.tableRow = [];
				Object.keys(row).forEach((key) => {
					this.tableRow[key] = row[key].id;

				});
			},
			/**
			 * 删除选中行
			 */
			delect() {
				if (this.tableRow.length <= 0) {
					return this.$message.error("请选择删除行")
				} else {
					//开始实现你的伟大逻辑...
					console.log('删除成功ids', JSON.stringify(this.tableRow));
				}

			},
		},
	}
</script>
<style lang="less" scoped>
	.el-aside {
		width: 400px !important;
		padding: 42px 20px;
		background-color: #FFFFFF;
		margin-right: 10px;
	}

	.el-card {
		background-color: #f5f7f9;
	}

	.el-pagination {
		margin-top: 15px;
	}

	.el-main {
		background-color: #FFFFFF;

	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-tag {
		margin-left: 4px;
		margin-bottom: 4px;
	}

	.tisp {
		color: orangered;
		font-size: 12px;
	}
</style>
