<template>
	<!-- 面包屑导航 -->
	<div class="main">
		<el-breadcrumb>
			<el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>模型管理</el-breadcrumb-item>
			<el-breadcrumb-item>维护模型</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<!-- 搜索区域 -->
			<el-form v-if="search.status">
				<el-row :gutter="50">
					<template v-for="(item,index) in search.list" :key="item.index">
						<el-col v-if="search.list[index].search==true" :span="7">
							<el-form-item :label="item.name" v-if="item.type=='text'">
								<el-input size="small" :placeholder="item.name" v-model="search.list[index].value"
									clearable @clear="getTableData()">
								</el-input>
							</el-form-item>

							<el-form-item :label="item.name" v-if="item.type=='time'">
								<el-date-picker size="small" v-model="search.list[index].value" type="daterange"
									:shortcuts="shortcuts" range-separator="至" start-placeholder="开始日期"
									end-placeholder="结束日期" unlink-panels format="YYYY-MM-DD" value-format="YYYY/MM/DD">
								</el-date-picker>
							</el-form-item>
							<el-form-item :label="item.name" v-if="item.type=='select'">
								<el-select size="small" v-model="search.list[index].value"
									@change="searchSelect(search.list[index].value)" placeholder="请选择" clearable>
									<el-option v-for="vo in options" :key="vo.value" :label="vo.label"
										:value="vo.value">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</template>
					<el-col :span="4">
						<el-form-item>
							<el-button size="small" @click="getTableData(true)" icon="el-icon-search">搜索</el-button>
							<el-button size="small" icon="el-icon-search">重置</el-button>
						</el-form-item>
					</el-col>

				</el-row>
			</el-form>
			<!-- 搜索区域end -->
		</el-card>

		<el-card>
			<el-container>
				<el-aside>
					<!-- table输出视图 -->
					<el-button :model="addFormLeft" type="success" @click="dialogVisibleLeftAdd = true">添加模型</el-button>
					<!-- <el-button type="danger" @click="delect()">禁 用</el-button> -->
					<!-- <el-button type="warning" @click="delect()">导出</el-button> -->
					<el-table :data="tableData" @selection-change="tableSelect" border stripe>
						<!-- <el-table-column type="selection" width="55"></el-table-column> -->
						<el-table-column type="index" :index='indexMethod' label="编号"></el-table-column>
						<el-table-column v-for="(items,i) in tableDataTemp" :key="i" :prop="i" :label="items.name">
							<template #header v-if="items.show==true">
								<span>
									{{items.name}}
								</span>
							</template>
							<template #default="scope">
								<div v-if="items.type=='array'">
									<el-tag type="info" v-for="arr in scope.row[i]" :key="arr.id">
										{{arr.name}}
									</el-tag>
								</div>
								<div v-else-if="items.type=='image'">
									<div class="block">
										<el-avatar shape="square" :size="50" :src="scope.row[i]"></el-avatar>
									</div>
								</div>
								<div v-else-if="items.type=='tag'">
									<div v-if="scope.row[i]==0">
										<el-tag type="danger">未认证</el-tag>
									</div>
									<div v-if="scope.row[i]==1">
										<el-tag type="success">已实名</el-tag>
									</div>
								</div>
								<div v-else-if="items.type=='select'">
									<el-switch v-model="scope.row[i]" @change="switchChange(scope.row)"
										active-color="#13ce66" inactive-color="#ff4949">
									</el-switch>
								</div>
								<div v-else>
									<span v-if="items.show==true">
										{{scope.row[i]}}
									</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="180px">
							<template #default="scope">

								<el-button type="danger" icon="el-icon-delete" size="mini"
									@click="editDialogLeft(scope.$index, scope.row)">修改
								</el-button>
								<el-button type="primary" icon="el-icon-edit" size="mini"
									@click="showEditDialogLeft(scope.$index, scope.row)">查看
								</el-button>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页区域 -->
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="queryInfo.pagenum" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>

				</el-aside>
				<el-main>
					<!-- 右则输出视图 -->
					<el-tabs v-model="activeName" v-if="editFormLeft">
						<el-tab-pane :label="'模型维护('+editFormLeft.items+')'" name="first">
							<el-button :model="editFormLeft" type="warning" @click="dialogVisibleRightAdd = true">添加步骤
							</el-button>
							<el-table :data="editFormLeft.symptomModel" @selection-change="tableSelect" border stripe>
								<!-- <el-table-column type="selection" width="55"></el-table-column> -->
								<el-table-column type="index" :index='indexMethod' label="编号"></el-table-column>
								<el-table-column prop="title" label="问题" width="480px"></el-table-column>
								<el-table-column prop="type" label="类型">
									<template #default="scope">
										<span v-if="scope.row.type==0">
											单 选
										</span>
										<span v-else-if="scope.row.type==1">
											多 选
										</span>
										<span v-else-if="scope.row.type==2">
											用户回复
										</span>
										<span v-else>
											时 间
										</span>
									</template>
								</el-table-column>
								<!-- <el-table-column prop="option" label="选项"></el-table-column> -->
								<el-table-column prop="nums" label="步骤数"></el-table-column>
								<el-table-column label="操作" width="100px">
									<template #default="scope">
										<el-button type="primary" icon="el-icon-edit" size="mini"
											@click="showRightEditDialogRight(scope.$index, scope.row)">编辑
										</el-button>
										<!-- <el-button type="danger" icon="el-icon-delete" size="mini" @click="removrRow(scope.row.id)">
								</el-button> -->
									</template>
								</el-table-column>
							</el-table>
						</el-tab-pane>
					</el-tabs>
					<el-tabs v-else>
						<div style="text-align: center;margin-top: 15%;color: #914247;">
							<span>请选择维护的模型</span>
						</div>
					</el-tabs>
					<!-- <el-popconfirm title="老实要比巨通过审核吗" @confirm="adopt">
						<template #reference>
							<el-button type="primary" icon="el-icon-edit" size="mini">
								审核通过
							</el-button>
						</template>
					</el-popconfirm>
					<el-popconfirm title="老实矛爱巨做医生吗" @confirm="reject()">
						<template #reference>
							<el-button class="el-button el-button--danger" type="primary" icon="el-icon-edit"
								size="mini">
								审核驳回
							</el-button>
						</template>
					</el-popconfirm> -->
					<!-- 右则输出视图 END -->
				</el-main>
			</el-container>
		</el-card>
		<!-- 卡片视图区域 -->
	</div>
	<!--左侧模态框-->
	<el-row :gutter="8">
		<el-col :span="4">
			<!-- 左侧新增表单区域 -->
			<el-dialog title="添加模型" v-model="dialogVisibleLeftAdd" width="30%" @close="closeLeftAdd()">
				<el-form :model="addFormLeft" ref="addFormLeft" label-width="100px">
					<el-form-item label="症状模型名称">
						<el-input v-model="addFormLeft.items" :placeholder="症状模型名称"></el-input>
					</el-form-item>
					<el-form-item label="症状模型描述">
						<el-input v-model="addFormLeft.describe" :placeholder="症状模型描述" type="textarea" rows="6">
						</el-input>
					</el-form-item>
				</el-form>
				<template #footer>
					<span class="dialog-footer">
						<el-button @click="dialogVisibleLeftAdd=false">取 消</el-button>
						<el-button type="primary" @click="funcAddLeft()">确 定</el-button>
					</span>
				</template>
			</el-dialog>
			<!-- 左侧新增表单区域 -->
			<!-- 左侧编辑表单区域 -->
			<el-dialog title="编辑模型" v-model="dialogVisibleLeftEdit" width="30%" @close="closeLeftEdit()">
				<el-form :model="editFormLeft" ref="editFormLeft" label-width="100px">

					<el-form-item label="症状模型名称">
						<el-input v-model="editFormLeft.items" :placeholder="症状模型名称"></el-input>
					</el-form-item>
					<el-form-item label="症状模型描述">
						<el-input v-model="editFormLeft.describe" :placeholder="症状模型描述" type="textarea" rows="6">
						</el-input>
					</el-form-item>
				</el-form>
				<template #footer>
					<span class="dialog-footer">
						<el-button @click="dialogVisibleLeftEdit=false">取 消</el-button>
						<el-button type="primary" @click="funcEditLeft()">确 定</el-button>
					</span>
				</template>
			</el-dialog>
			<!-- 左侧编辑表单区域 -->
		</el-col>
	</el-row>
	<!--左侧模态框 end-->
	<!--右则模态框-->
	<el-row :gutter="8">
		<el-col :span="4">
			<!-- 右则新增表单区域 -->
			<el-dialog title="添加步骤" v-model="dialogVisibleRightAdd" width="30%" @close="closeRightAdd()">
				<el-form :model="addFormRight" ref="addFormRight" label-width="100px">

					<el-form-item label="问题">
						<el-input v-model="addFormRight.title" :placeholder="步骤问题"></el-input>
					</el-form-item>

					<el-form-item label="步数">
						<el-input-number v-model="addFormRight.nums" :min="1" :max="120" @change="handleChange" />
					</el-form-item>
					<el-form-item label="类型">
						<el-radio v-model="addFormRight.type" label="0">单 选</el-radio>
						<el-radio v-model="addFormRight.type" label="1">多 选</el-radio>
						<el-radio v-model="addFormRight.type" label="2">用户回复</el-radio>
						<el-radio v-model="addFormRight.type" label="3">时间选择</el-radio>
					</el-form-item>


					<el-form-item label="疾病选项">
						<el-input v-model="addFormRight.option" :placeholder="疾病选项" type="textarea" rows="6"></el-input>
						<label class="tisp">填写规则<br>抽象词|实际病症词<br>中间用 | 线分割，一行代表一个症状得选择.</label>
					</el-form-item>
				</el-form>
				<template #footer>
					<span class="dialog-footer">
						<el-button @click="dialogVisibleRightAdd=false">取 消</el-button>
						<el-button type="primary" @click="funcAdd()">确 定</el-button>
					</span>
				</template>
			</el-dialog>
			<!-- 右则新增表单区域 -->
			<!-- 右则编辑表单区域 -->
			<el-dialog title="编辑步骤" v-model="dialogVisibleRightEdit" width="30%" @close="closeRightEdit()">
				<el-form :model="editFormRight" ref="addFormRef" label-width="100px">
					<el-form-item label="问题">
						<el-input v-model="editFormRight.title" :placeholder="步骤问题"></el-input>
					</el-form-item>

					<el-form-item label="步数">
						<el-input-number v-model="editFormRight.nums" :min="1" :max="120" @change="handleChange" />
					</el-form-item>
					<el-form-item label="类型">
						<el-radio v-model="editFormRight.type" :label="0">单 选</el-radio>
						<el-radio v-model="editFormRight.type" :label="1">多 选</el-radio>
						<el-radio v-model="editFormRight.type" :label="2">用户回复</el-radio>
						<el-radio v-model="editFormRight.type" :label="3">时间选择</el-radio>
					</el-form-item>
					<el-form-item label="疾病选项">
						<el-input v-model="editFormRight.option" :placeholder="疾病选项" type="textarea" rows="6">
						</el-input>
						<label class="tisp">填写规则<br>抽象词|实际病症词<br>中间用 | 线分割，一行代表一个症状得选择.</label>
					</el-form-item>
				</el-form>
				<template #footer>
					<span class="dialog-footer">
						<el-button @click="dialogVisibleRightEdit=false">取 消</el-button>
						<el-button type="primary" @click="funcEdit()">确 定</el-button>
					</span>
				</template>
			</el-dialog>
			<!-- 右则编辑表单区域 -->
		</el-col>
	</el-row>
	<!--右则模态框 end-->
</template>
<script>
	import {
		index,
		symptomAdd,
		symptomEdit,
		stepEdit,
		stepAdd
	} from '../../api/robot.js'
	export default {
		data() {
			return {
				activeName: 'first', //选显卡默认
				defaultProps: {
					children: 'children',
					label: 'title',
				},
				options: [{
					value: '1', //不加双引号，el-select标签v-model绑定不了1 
					label: '正常'
				}, {
					value: '0',
					label: '禁止'
				}],
				shortcuts: [{
					text: '最近一周',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						return [start, end]
					}
				}, {
					text: '最近一个月',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						return [start, end]
					}
				}, {
					text: '最近三个月',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						return [start, end]
					}
				}],
				//获取表格参数对象
				queryInfo: {
					query: {},
					//当前的页面
					pagenum: 1,
					//当前每页显示多少条数据
					pagesize: 10,
				},
				//接口表格数据
				tableData: [],
				//本地表格临时数据
				tableDataTemp: {},
				//选中的row
				tableRow: [],
				//选中行下标
				tableIndex: [],
				//支持搜索的字段
				search: {
					status: true, //开启表单搜索
					list: {
						items: {
							show: true, //表格是否显示字段
							search: true,
							modal: true,
							type: 'text', //列类型,决定了搜索和显示的样式
							name: '症状模型', //列名称
							value: '', //当前搜索情况下的值
							//验证规则
							rules: [{
									required: true,
									message: '请输入手机号码',
									trigger: 'blur',
								},
								{
									min: 11,
									max: 11,
									message: '请输入正确手机号码',
									trigger: 'blur',
								},
							]
						},
						step: {
							show: true, //表格是否显示字段
							search: false,
							modal: false,
							type: 'text',
							name: '模型步数',
							value: '',
							//验证规则
							rules: [{
									required: true,
									message: '请输入日期',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},

					}

				},
				//状态
				total: 0,
				//左侧参数
				dialogVisibleLeftEdit: false, //模态框新增状态
				dialogVisibleLeftAdd: false, //模态框编辑状态
				editFormLeft: '', //当前编辑对象
				addFormLeft: {
					items:'',
					describe:''
				}, //当前新增对象
				//右则参数
				dialogVisibleRightEdit: false, //模态框展示
				dialogVisibleRightAdd: false, //模态框展示
				addFormRight: {},
				editFormRight: {},
			}
		},
		created() {
			this.initTable();
			this.getTableData(false);
		},
		methods: {
			/**
			 * 步骤计数器监听
			 */
			handleChange(index) {
				this.addFormRight.nums = index
				console.log('开始计数', index)
			},
			/**
			 * 右则关闭编辑模态框
			 */
			closeRightEdit() {
				this.dialogVisibleRightEdit = false;
			},
			/**
			 * 左则关闭编辑模态框
			 */
			closeLeftEdit() {
				this.dialogVisibleLeftEdit = false;
			},
			/**
			 * 右则关闭新增模态框
			 */
			closeRightAdd() {
				console.log('关闭了新增模态框')
				this.dialogVisibleRightAdd = false;
				//this.$refs.addFormRight.resetFields(); //清空表单数据
				this.addFormRight = {} //清空表单数据
			},
			/**
			 * 左则关闭新增模态框
			 */
			closeLeftAdd() {
				console.log('关闭了新增模态框')
				this.dialogVisibleLeftAdd = false;
				//this.$refs.addFormRight.resetFields(); //清空表单数据
				this.addFormLeft = {} //清空表单数据
			},
			/**
			 * 右则点击编辑事件
			 */
			showRightEditDialogRight(index, row) {
				console.log(row)
				this.editFormRight = row
				this.dialogVisibleRightEdit = true
			},
			/**
			 * 左则点击编辑事件
			 */
			showRightEditDialogLfet(index, row) {
				console.log('点击了左侧编辑',row)
				this.editFormLeft = row
				this.dialogVisibleLeftEdit = true
			},
			/**
			 * 右则点击新增事件
			 */
			showRightAddDialog() {
				this.dialogVisibleRightAdd = true
			},
			/**
			 * 左则点击新增事件
			 */
			showLeftAddDialog() {
				this.dialogVisibleLeftAdd = true
			},
			/**
			 * 提交右则新增方法
			 */
			async funcAdd() {
				this.addFormRight.symptom_id = this.editFormLeft.id;
				await stepAdd(this.addFormRight);
				console.log('提交新增方法', this.addFormRight)
			},
			/**
			 * 提交编右则编辑方法
			 */
			async funcEdit() {
				console.log('提交方法', this.editFormRight)
				await stepEdit(this.editFormRight);
				this.dialogVisibleRightEdit = false
				return this.$message.success('步骤编辑成功');
			},
			/**
			 * 提交左则新增方法
			 */
			async funcAddLeft() {
				console.log('提交left新增方法', this.addFormLeft)
				await symptomAdd({
						items: this.addFormLeft.items,
						describe: this.addFormLeft.describe
				});
				this.dialogVisibleLeftAdd = false;
				this.getTableData(false);
				return this.$message.success('模型新增成功');
			},
			/**
			 * 提交编左则编辑方法
			 */
			async funcEditLeft() {
				console.log('提交左侧left方法', this.editFormLeft)
				await symptomEdit({
					id: this.editFormLeft.id,
					items: this.editFormLeft.items,
					describe: this.editFormLeft.describe
				});
				this.tableData[this.tableIndex].items = this.editFormLeft.items
				this.dialogVisibleLeftEdit = false;
				return this.$message.success('编辑模型成功');
			},
			/**
			 * 左侧查看点击监听
			 */
			showEditDialogLeft(index, row) {
				console.log('编辑行数据', row);
				this.editFormLeft = JSON.parse(JSON.stringify(row));
				this.editDealogVisibleLeft = true;
				//重新清洗图片格式成数组
				this.editFormLeft.picture = this.getParamValues('cdn', this.editFormLeft.picture)
			},
			/**
			 * 左侧编辑模态框
			 */
			editDialogLeft(index, row) {
				this.editFormLeft = JSON.parse(JSON.stringify(row));
				this.dialogVisibleLeftEdit = true;
				this.tableIndex = index
				console.log('点击了左侧编辑', row)
			},
			/**
			 * 初始化表格数据
			 * 为配合动态渲染表格列，需生成tableDataTemp渲染
			 */
			initTable() {
				let _this = this;
				Object.keys(this.search.list).forEach(function(key) {
					if (_this.search.list[key].show == true) {
						_this.tableDataTemp[key] = _this.search.list[key];
					}
				});
			},
			indexMethod(index) {
				let curpage = this.queryInfo.pagenum; //单前页码，具体看组件取值
				let limitpage = this.queryInfo.pagesize; //每页条数，具体是组件取值
				return (index + 1) + (curpage - 1) * limitpage;
			},
			searchSelect(value) {
				console.log('您下拉选择了！', value);
				this.search.list.status.value = value;
				console.log('最新状态', this.search.list.status.value);
			},
			/**
			 * 加载接口数据
			 * value 是否搜索
			 */
			async getTableData(value) {
				let d = {};
				if (value) {
					//过滤筛选条件
					Object.keys(this.search.list).forEach((key) => {
						if (this.search.status == true) {
							if (this.search.list[key].value != '') {
								//console.log(key+'资料',this.search.list[key].value);
								d[key] = {
									'value': this.search.list[key].value,
									'type': this.search.list[key].type,
								}
							}
						}
					});
				}
				//默认追加角色获取数据
				// d['auth'] = {
				// 	type: 'text',
				// 	value: 0
				// };
				let res = await index({
					filter: JSON.stringify(d),
					page: this.queryInfo.pagenum,
					limit: this.queryInfo.pagesize
				});
				res.data.list.map((item) => {
					if (item.user_id == 0) {
						item.name = '游客'
						item.avatar = 'http://doctor.img.123xk.cn/empty5.png'
					}
					item.step = item.symptomModel.length
				});
				this.tableData = res.data.list
				this.total = res.data.pagination.total
			},
			/**
			 * @param {Object} val
			 * 监听pagesize改变事件
			 */
			handleSizeChange(val) {
				this.queryInfo.pagesize = val
				this.getTableData()
			},
			/**
			 * @param {Object} val
			 * 监听页码值改变事件
			 */
			handleCurrentChange(val) {
				this.queryInfo.pagenum = val
				this.getTableData()
			},
			/**
			 * @param {Object} row
			 * 监听swit开关的改变状态
			 */
			async switchChange(row) {
				//开始实现你的伟大逻辑...
				console.log('switch切换成功', row);
				return this.$message.success('切换成功');
			},
			/**
			 * 返回一维数组某列
			 * @param {Object} name 需要返回的属性名
			 * @param {Object} arr 原始数据
			 */
			getParamValues(name, arr) {
				var ret = []
				for (var i = 0, len = arr.length; i < len; i++) {
					ret.push(arr[i][name])
				}
				return ret
			},

			/**
			 * 关闭表单编辑模态框
			 */
			edotDialogClosed() {
				this.$refs.editFormRef.resetFields()
			},
			/**
			 * 表单编辑
			 */
			tableEditData() {
				console.log('编辑的数据', JSON.stringify(this.editFormLeft));
				this.$refs.editFormRef.validate(async (valid) => {
					if (valid) {
						this.editDealogVisible = false;
						this.getTableData(false)
						return this.$message.success('编辑成功');
					} else {
						console.log('验证不通过');
						return false;
					}

				})
			},



			/**
			 * 删除选中行
			 * @param {Object} id
			 */
			async removrRow(id) {
				//弹框提示
				const confirmResult = await this.$confirm(
					'此操作将永久删除该用户, 是否继续?',
					'提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					}
				).catch((err) => err)
				if (confirmResult != 'confirm') {
					//取消操作
				} else {
					this.$message.success('删除成功,ID:' + id)

				}
			},
			/**
			 * 选项改变触发事件
			 * @param {Object} row
			 */
			tableSelect(row) {
				this.tableRow = [];
				Object.keys(row).forEach((key) => {
					this.tableRow[key] = row[key].id;
					//console.log(this.tableRow[key]);
				});

			},
			/**
			 * 删除选中行
			 */
			delect() {
				if (this.tableRow.length <= 0) {
					return this.$message.error("请选择删除行")
				} else {
					//开始实现你的伟大逻辑...
					console.log('删除成功ids', JSON.stringify(this.tableRow));
				}

			},
		},
	}
</script>
<style lang="less" scoped>
	.el-table {
		.el-button {
			background-color: #914247;
			border: none;
			background: #914247;
		}
	}

	.el-aside {
		width: 600px !important;
		padding: 1.25rem;
		background-color: #FFFFFF;
		margin-right: 10px;
	}

	.el-card {
		background-color: #f5f7f9;
	}

	.el-pagination {
		margin-top: 15px;
	}

	.el-main {
		background-color: #FFFFFF;

	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-tag {
		margin-left: 4px;
		margin-bottom: 4px;
	}

	.tisp {
		color: orangered;
		font-size: 12px;
	}

	.row {
		padding: 5px 0;

		.describe {
			padding: 5px 0;
		}

		.time {
			.lable {
				color: #914247;
				font-weight: bold;
			}
		}

		.card {
			.title {
				color: #914247;
				font-weight: bold;
			}
		}

		.pic {
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.el-image {
				margin-right: 0.625rem;
				border-radius: 0.625rem;
				width: 6.25rem;
				height: 6.25rem;
			}
		}
	}

	.user-info {
		display: flex;
		align-items: center;

		.left,
		.right {
			flex: 0 0 50%;
			display: flex;
			align-items: center;

			.avatar {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		}

		.info {
			display: flex;
			flex-direction: column;
			margin-left: 0.625rem;

			.name span {
				margin-right: 5px;
			}
		}
	}

	.chat {
		display: flex;
		flex-direction: column;

		.time {
			color: #d9d9d9;
			text-align: center;
			margin: 1.625rem 0;

			span {
				font-size: 15px;
				border-radius: 30px;
				background-color: #f1f1f1;
				padding: 2px 10px;
			}
		}

		.info {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			.message {
				padding: 10px 20px;
				margin: 0 10px;
				border-radius: 5px;
			}

			.message_text {
				background-color: #FFFFFF;
			}

			.el-image {
				border-radius: 5px;
			}
		}
	}
</style>
