import {
	http
} from '../http.js'

/**
 * 应用列表
 * @param {Object} option
 */
export function appList(option) {
	return http('admin/app/index', 'post', option)
}
/**
 * app编辑
 * @param {Object} option
 */
export function appEdit(option) {
	return http('admin/app/edit', 'post', option)
}
/**
 * 更新列表
 * @param {Object} option
 */
export function version(option) {
	return http('admin/app/version', 'post', option)
}
/**
 * 版本编辑
 * @param {Object} option
 */
export function versionEdit(option) {
	return http('admin/app/versionEdit', 'post', option)
}
/**
 * 版本新增
 * @param {Object} option
 */
export function versionAdd(option) {
	return http('admin/app/versionAdd', 'post', option)
}
/**
 * 获取七牛云上传token
 * @param {Object} option
 */
export function qiniuToken(option) {
	return http('common/upload/getQiniuToken', 'post', option)
}
/**
 * 获取系统全局配置
 * @param {Object} option
 */
export function getConfig(option) {
	return http('admin/config/details', 'post', option)
}
/**
 * 编辑系统全局配置
 * @param {Object} option
 */
export function setConfig(option) {
	return http('admin/config/edit', 'post', option)
}


