<template>
	<div>
		<!-- 面包屑导航 -->
		<header>数据采集系统6.0 Plus</header>
		<!-- 卡片视图区域 -->
		<el-card>
			<el-row :gutter="24">

				<el-button v-if="initStatus==false" type="primary" @click="setsys()" :loading="initButton">初始化
				</el-button>
				<el-button v-if="initStatus==true" type="primary" @click="reset()" :loading="initButton">重置请求
				</el-button>



				<div class="input">
					<el-input  v-model="url" placeholder="请输入采集网址" :disabled="initStatus"></el-input>
				</div>

				<div class="line">|</div>
				<el-button type="danger" @click="ends()">停止</el-button>
				<el-button type="warning" @click="delect()">暂停</el-button>
				<el-button v-if="initStatus==true" :loading="status" type="success" @click="startall()">启动采集
				</el-button>
			</el-row>

		</el-card>
		<el-card v-if="initStatus" v-loading="initButton">
			<el-row>
				<div class="tisp">
					<span>可采集<b>{{init.total}}条</b>,共<b>{{init.page}}</b>页</span>
					<span>当前采集<b>{{page}}</b>页,已采集<b>{{list.length}}条</b>,剩余集采<b
							style="color: #AA1111;">{{list.length-init.total}}</b>条</span>

				</div>
			</el-row>
		</el-card>
		<el-card v-if="initStatus" v-loading="initButton">
			<ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
				<li v-for="(item,index) in list" class="infinite-list-item" :key="item">{{index+1}},{{item}}</li>
			</ul>
		</el-card>

	</div>
</template>
<script>
	import {
		initsys,
		index
	} from "../api/reptile.js"
	export default {
		data() {
			return {
				initButton: false,
				initStatus: false,
				init: {},
				url: '',
				list: [],
				page: 1, //记录当前采集页码
				status: false, //是否处于采集状态
			}
		},
		created() {
			//this.getTableData()
		},
		methods: {
			ends() {
				this.status = false;
			},
			reset() {
				this.status = false;
				this.initStatus = false;
			},
			load() {
				console.log('触底啦！');
			},
			async setsys() {
				console.log('初始化成功!');
				this.page = 1
				this.list = []
				this.initButton = true
				if(this.url==""){
					this.initButton = false
				}
				//初始化采集
				let res = await initsys({
					url: this.url,
				});
				this.init = res;
				this.initStatus = true;
				this.initButton = false;
				
			},
			async startall() {

				//console.log(this.init.page);
				if (this.list.length >= this.init.init) {
					this.$message.error('数据已采完毕，不能重复下手！');
				} else {
					this.page = 0;
					this.status = true;
					let i = this.page; //当前采集页
					let res = '';
					for (i; i <= this.init.page; i++) {
						if (this.status == true) {
							res = await index({
								url: this.url,
								page: i
							});
							this.page = res.page;
							for (let j in res.data) {
								console.log(res.data[j]);
								this.list.unshift(res.data[j]);
							}

						} else {
							console.log('已停止请求!');
						}
					}
					this.status = false;

					console.log(res);
				}

			},


		}
	}
</script>
<style lang="less" scoped>
	header {
		padding: 1.25rem;
		font-size: 30px;
		color: #417ff9;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.tisp {
		display: flex;
		align-items: center;
		justify-items: center;
		margin-left: 20px;

		span {
			margin-right: 1.25rem;
		}
	}

	.el-row {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.input {
		width: 15%;
		display: flex;
		align-items: center;
		justify-content: center;

		.el-input {
			padding-left: 10px;
		}
	}

	.line {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 20px;
		font-size: 20px;
	}

	.infinite-list {

		height: 500px;
	}
</style>
