<template>
	<!-- 面包屑导航 -->
	<el-breadcrumb>
		<el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
		<el-breadcrumb-item>短信管理</el-breadcrumb-item>
		<el-breadcrumb-item>短信列表</el-breadcrumb-item>
	</el-breadcrumb>

	<!-- 卡片视图区域 -->
	<el-card>
		<!-- 搜索区域 -->
		<el-form v-if="search.status">
			<el-row :gutter="50">
				<template v-for="(item,index) in search.list" :key="item.index">
					<el-col v-if="search.list[index].search==true" :span="7">
						<el-form-item :label="item.name" v-if="item.type=='text'">
							<el-input size="small" :placeholder="item.name" v-model="search.list[index].value" clearable
								@clear="getTableData()">
							</el-input>
						</el-form-item>

						<el-form-item :label="item.name" v-if="item.type=='time'">
							<el-date-picker size="small" v-model="search.list[index].value" type="daterange"
								:shortcuts="shortcuts" range-separator="至" start-placeholder="开始日期"
								end-placeholder="结束日期" unlink-panels format="YYYY-MM-DD" value-format="YYYY/MM/DD">
							</el-date-picker>
						</el-form-item>
						<el-form-item :label="item.name" v-if="item.type=='select'">
							<el-select size="small" v-model="search.list[index].value"
								@change="searchSelect(search.list[index].value)" placeholder="请选择" clearable>
								<el-option v-for="vo in options" :key="vo.value" :label="vo.label" :value="vo.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</template>
				<el-col :span="4">
					<el-form-item>
						<el-button size="small" @click="getTableData(true)" icon="el-icon-search">搜索</el-button>
						<el-button size="small" icon="el-icon-search">重置</el-button>
					</el-form-item>
				</el-col>

			</el-row>
		</el-form>
		<!-- 搜索区域end -->

		<!-- table输出视图 -->
		<el-table :data="tableData" @selection-change="tableSelect" border stripe>
			<el-table-column type="selection" width="55">
			</el-table-column>
			<el-table-column type="index" :index='indexMethod' label="编号"></el-table-column>
			<el-table-column v-for="(items,i) in tableDataTemp" :key="i" :prop="i" :label="items.name">
				<template #header v-if="items.show==true">
					<span>
						{{items.name}}
					</span>
				</template>
				<template #default="scope">
					<div v-if="items.type=='array'">
						<el-tag type="info" v-for="arr in scope.row[i]" :key="arr.id">
							{{arr.name}}
						</el-tag>
					</div>
					<div v-else-if="items.type=='image'">
						<div class="block">
							<el-avatar shape="square" :size="50" :src="scope.row[i]"></el-avatar>
						</div>
					</div>
					<div v-else-if="items.type=='tag'">
						<div v-if="scope.row[i]==0">
							<el-tag type="danger">未认证</el-tag>
						</div>
						<div v-if="scope.row[i]==1">
							<el-tag type="success">已实名</el-tag>
						</div>
					</div>
					<div v-else-if="items.type=='select'">
						<el-switch v-model="scope.row[i]" @change="switchChange(scope.row)" active-color="#13ce66"
							inactive-color="#ff4949">
						</el-switch>
					</div>
					<div v-else>
						<span v-if="items.show==true">
							{{scope.row[i]}}
						</span>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页区域 -->
		<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="queryInfo.pagenum" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pagesize"
			layout="total, sizes, prev, pager, next, jumper" :total="total">
		</el-pagination>
	</el-card>
</template>
<script>
	import {
		sms,
	} from '../../api/index.js'
	export default {
		data() {
			return {
				defaultProps: {
					children: 'children',
					label: 'title',
				},
				treeData: [],
				options: [{
					value: '1', //不加双引号，el-select标签v-model绑定不了1 
					label: '正常'
				}, {
					value: '0',
					label: '禁止'
				}],
				shortcuts: [{
					text: '最近一周',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						return [start, end]
					}
				}, {
					text: '最近一个月',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						return [start, end]
					}
				}, {
					text: '最近三个月',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						return [start, end]
					}
				}],
				//获取表格参数对象
				queryInfo: {
					query: {},
					//当前的页面
					pagenum: 1,
					//当前每页显示多少条数据
					pagesize: 10,
				},
				//接口表格数据
				tableData: [],
				//本地表格临时数据
				tableDataTemp: {},

				//支持搜索的字段
				search: {
					status: true, //开启表单搜索
					list: {
						mobile: {
							show: true, //表格是否显示字段
							search: true,
							modal: true,
							type: 'text', //列类型,决定了搜索和显示的样式
							name: '手 机', //列名称
							value: '', //当前搜索情况下的值
							//验证规则
							rules: [{
									required: true,
									message: '请输入手机号码',
									trigger: 'blur',
								},
								{
									min: 11,
									max: 11,
									message: '请输入正确手机号码',
									trigger: 'blur',
								},
							]
						},
						content: {
							show: true, //表格是否显示字段
							search: false, //是否搜索字段
							modal: true, //开启模态框显示
							type: 'text', //列类型,决定了搜索和显示的样式
							name: '验证码', //列名称
							value: '', //当前搜索情况下的值
							tisp: '为空则为手机登录',
							//验证规则
							rules: [{
									required: false,
									message: '请输入账号',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},
						type: {
							show: true, //表格是否显示字段
							search: false, //是否搜索字段
							modal: true, //开启模态框显示
							type: 'text', //列类型,决定了搜索和显示的样式
							name: '类 型', //列名称
							value: '', //当前搜索情况下的值
							tisp: '为空则为手机登录',
							//验证规则
							rules: [{
									required: false,
									message: '请输入账号',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},
						create_time: {
							show: true, //表格是否显示字段
							search: true,
							modal: true,
							type: 'time',
							name: '发送时间',
							value: '',
							//验证规则
							rules: [{
									required: true,
									message: '请输入日期',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},

					}

				},
				//状态
				total: 0,
			}
		},
		created() {
			this.initTable();
			this.getTableData(false);
		},
		methods: {
			/**
			 * 初始化表格数据
			 * 为配合动态渲染表格列，需生成tableDataTemp渲染
			 */
			initTable() {
				let _this = this;
				Object.keys(this.search.list).forEach(function(key) {
					if (_this.search.list[key].show == true) {
						_this.tableDataTemp[key] = _this.search.list[key];
					}
				});
			},
			indexMethod(index) {
				let curpage = this.queryInfo.pagenum; //单前页码，具体看组件取值
				let limitpage = this.queryInfo.pagesize; //每页条数，具体是组件取值
				return (index + 1) + (curpage - 1) * limitpage;
			},
			/**
			 * 加载接口数据
			 * value 是否搜索
			 */
			async getTableData(value) {
				let d = {};
				if (value) {
					//过滤筛选条件
					Object.keys(this.search.list).forEach((key) => {
						if (this.search.status == true) {
							if (this.search.list[key].value != '') {
								//console.log(key+'资料',this.search.list[key].value);
								d[key] = {
									'value': this.search.list[key].value,
									'type': this.search.list[key].type,
								}
							}
						}
					});
				}
				let res = await sms({
					filter: JSON.stringify(d),
					page: this.queryInfo.pagenum,
					limit: this.queryInfo.pagesize
				});
				this.tableData = res.data.list
				this.total = res.data.pagination.total
			},
			/**
			 * @param {Object} val
			 * 监听pagesize改变事件
			 */
			handleSizeChange(val) {
				this.queryInfo.pagesize = val
				this.getTableData()
			},
			/**
			 * @param {Object} val
			 * 监听页码值改变事件
			 */
			handleCurrentChange(val) {
				this.queryInfo.pagenum = val
				this.getTableData()
			},
			/**
			 * 返回一维数组某列
			 * @param {Object} name 需要返回的属性名
			 * @param {Object} arr 原始数据
			 */
			getParamValues(name, arr) {
				var ret = []
				for (var i = 0, len = arr.length; i < len; i++) {
					ret.push(arr[i][name])
				}
				return ret
			},

		},
	}
</script>
<style lang="less" scoped>
	.el-pagination {
		margin-top: 15px;
	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-tag {
		margin-left: 4px;
		margin-bottom: 4px;
	}

	.tisp {
		color: orangered;
		font-size: 12px;
	}
</style>
