import {
	http
} from '../http.js'

//初始化采集
export function initsys(option) {
	return http('index/GoodsIndex/initsys', 'post', option)
}
//开始采集接口
export function index(option) {
	return http('index/GoodsIndex/index', 'post', option)
}
