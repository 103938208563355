import {
	http
} from '../http.js'
//登录接口
export function login(option) {
	return http('admin/admin.index/login', 'post', option)
}
//获取当前权限菜单
export function menus() {
	return http('admin/menu/index', 'post', {})
}
/**
 * 获取所有菜单
 * @param {Object} option
 */
export function getMenus(option) {
	return http('admin/menu/getMenus', 'post', option)
}
/**
 * 获取所有菜单
 * @param {Object} option
 */
export function sms(option) {
	return http('admin/sms/index', 'post', option)
}
/**
 * 修改当前用户密码
 * @param {Object} option
 */
export function changePassword(option) {
	return http('admin/admin.index/changePassword', 'post', option)
}