import axios from 'axios';
import {
	ElMessage,
} from 'element-plus'

//axios拦截与配置
axios.defaults.baseURL = 'https://doctor.123xk.cn/';
axios.interceptors.request.use(config => {
	config.headers.Authorization = window.sessionStorage.getItem('token');
	return config
})
const http = function(url, methed, options) {
	return new Promise((resolve, reject) => {

		axios({
				url: url,
				method: methed,
				data: options
			})
			.then(response => {
				//console.log(response.data);
				if (response.data.code === 1) {
					return resolve(response.data)
				} else if (response.data.code === -1) {
					// ElMessage.error('请求状态码'+response.data.code)
					window.location.href = "/login";
				} else {
					ElMessage.error(response.data.msg)
					//ElMessage.error('请求失败')
				}
			})
			.catch(e => {
				ElMessage.error('服务器无响应')
				return reject(e)
			});
	})
};
export {
	http
}
