<template>
	<div>
		<!-- 面包屑导航 -->
		<el-breadcrumb>
			<el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>药品管理</el-breadcrumb-item>
			<el-breadcrumb-item>中草药编辑</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-form label-position="left" label-width="80px">
				<el-form-item label="草药名称">
					<el-input placeholder="请输入草药名称" v-model="article.name" clearable></el-input>
				</el-form-item>
				<el-form-item label="草药图">
					<el-upload class="avatar-uploader" ref="upload" :limit="2" :action="imgUrl" :show-file-list="false"
						:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="article.cover_cdn" :src="article.cover_cdn" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>

					</el-upload>
				</el-form-item>
				<el-form-item label="描述内容">
					<editor :init="init" api-key='ck6c1h9io2rg4s79wg2w9wh8wgj1o1wkcg2flbwps11xaaoh'
						v-model="article.content">
					</editor>
				</el-form-item>
				
				<!-- <el-form-item label="开启评论">
					<el-radio-group v-model="article.is_idea">
						<el-radio :label="0">允许</el-radio>
						<el-radio :label="1">禁止</el-radio>
					</el-radio-group>
				</el-form-item> -->
				
				
				<!-- <el-form-item label="文章分类" prop="category_id">
					<el-cascader v-model="article.category_id" :props="propMenu" :options="options"
						@change="changeMenu" clearable :placeholder="articleCategoryName" :show-all-levels="true">
					</el-cascader>
				</el-form-item> -->
				
				<!-- <el-form-item label="发布日期">
					<el-date-picker v-model="article.update_time" type="datetime" placeholder="选择日期时间">
					</el-date-picker>
				</el-form-item> -->
				<el-form-item>
					<el-button type="primary" @click="setArticle()">确认发布</el-button>
				</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>
<script>
	// 引入tinymce主 件
	//import tinymce from './../../static/tinymce/tinymce'
	import {
		edit,
		details,
		getCategory
	} from "../../api/herbs.js"
	import Editor from '@tinymce/tinymce-vue'
	export default {
		components: {
			'editor': Editor,
		},
		data() {
			return {
				imgUrl: this.$BASE_URL+'/common/upload/index', //主图上传地址
				ids: '',
				article: {
					name: '',
					content: '',
					is_idea: 0,
					update_time: '',
					cover: '',
					cover_cdn:'',
					category_id: ''
				},
				comment: 1,
				init: {
					plugins: ' lists image wordcount autoresize code',
					toolbar: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent  | undo redo | link unlink image  | removeformat | code',
					branding: false,
					min_height: 300,
					language: 'zh_CN', //注意大小写
					images_upload_handler: function(blobInfo, succFun, failFun) {
						var xhr, formData;
						var file = blobInfo.blob(); //转化为易于理解的file对象
						xhr = new XMLHttpRequest();
						xhr.withCredentials = false;
						xhr.open('POST', 'https://doctor.123xk.cn/common/upload/index');
						xhr.onload = function() {
							var json;
							if (xhr.status != 200) {
								failFun('HTTP Error: ' + xhr.status);
								return;
							}
							json = JSON.parse(xhr.responseText);
							if (!json || typeof json.data.cdn != 'string') {
								failFun('Invalid JSON: ' + xhr.responseText);
								return;
							}
							succFun(json.data.cdn);
						};
						formData = new FormData();
						formData.append('file', file, file.name); //此处与源文档不一样
						xhr.send(formData);
					}
				},
				options: [],
				articleCategoryName:'',//级联临时回显选中的类目
				//文章分类参数
				propMenu: {
					label: 'name',
					value: 'id',
					children: 'children',
					checkStrictly: true,
					lazy: true,
					lazyLoad: this.lazyLoads,
				},
			}
		},
		created() {
			this.ids = this.$route.query.ids;
			this.getCategory();
			this.getArticle();
		},
		methods: {
			/**
			 * Cascader组件懒加载数据
			 * @param {Object} node 当前选中的节点
			 * @param {Object} resolve 加载完成后的回调
			 */
			async lazyLoads(node, resolve) {
				if(node.data.id!=undefined){
					let res = await getCategory({
						pid: node.data.id,
					});
					console.log('数据集是',res.data.list)
					resolve(res.data.list);
				}else{
					console.log('node:', node.data.id);
				}
				
			},
			/**
			 * 菜单改变父级
			 */
			changeMenu(e) {
				if (e == null) {
					this.article.category_id = 0;
				} else {
					let arr = JSON.parse(JSON.stringify(e));
					this.article.category_id = arr.pop();
					console.log('当前值：', this.article.category_id)
				}
			
			},
			async setArticle() {
				console.log('编辑内容', JSON.parse(JSON.stringify(this.article)))
				delete this.article.articleColumn;
				await edit(this.article);
				this.$message.success('编辑成功');
				this.$router.push({
					path: './index'
				})

			},
			async getCategory() {
				let res = await getCategory({
					page: 1,
					limit: 5000
				});
				res.data.list.map((item) => {
					if (item.status == 1) {
						item.status = false;
					} else {
						item.status = true;
					}
				});
				this.options = res.data.list;
			},
			async getArticle() {
				let res = await details({
					id: this.ids
				});
				this.article = res.data
				this.article.category_id = res.data.articleColumn[0].id;
				this.articleCategoryName = res.data.articleColumn[0].name;
			},
			handleAvatarSuccess(res, file) {
				console.log(file);
				//this.article.cover = URL.createObjectURL(file.raw);
				this.article.cover = res.data.all;
				this.article.cover_cdn = res.data.cdn;
				this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
			},
			beforeAvatarUpload(file) {
				console.log('开始上传')
				const arr = [
					'image/jpeg',
					'image/png',
					'image/gif',
				]
				const res = arr.indexOf(file.type);
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (res <= -1) {
					this.$message.error('上传头像图片格式不正确');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return res && isLt2M;
			}
		}
	}
</script>
<style lang="less">
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		width: 100px;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		text-align: center;
	}
</style>
