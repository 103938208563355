<template>
	<div>
		<!-- 面包屑导航 -->
		<el-breadcrumb>
			<el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>采集管理</el-breadcrumb-item>
			<el-breadcrumb-item>采集数据</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-form label-position="left" label-width="80px">
				<el-form-item label="文章标题">
					<el-input placeholder="请输入内容" v-model="article.title" clearable></el-input>
				</el-form-item>
				<el-form-item label="文章封面">
					<el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
						:show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="imageUrl" :src="imageUrl" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="文章内容">
					<editor :init="init" api-key='ck6c1h9io2rg4s79wg2w9wh8wgj1o1wkcg2flbwps11xaaoh'
						v-model="article.content">
					</editor>
				</el-form-item>
				<el-form-item label="开启评论">
					<el-radio-group v-model="article.is_idea">
						<el-radio :label="0">允许</el-radio>
						<el-radio :label="1">禁止</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="文章分类">
					<el-select v-model="article.articleColumn[0].id" filterable placeholder="请选择">
						<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"
							:disabled="item.status">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="发布日期">
					<el-date-picker v-model="article.update_time" type="datetime" placeholder="选择日期时间">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary">确认发布</el-button>
				</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>
<script>
	// 引入tinymce主 件
	//import tinymce from './../../static/tinymce/tinymce'
	import {
		article,
		category
	} from "../../api/article.js"
	import Editor from '@tinymce/tinymce-vue'
	export default {
		components: {
			'editor': Editor,
		},
		data() {
			return {
				article: {
					title: '',
					content: '',
					update_time: '',
					articleColumn: [{
						id: ''
					}]
				},
				imageUrl: '',
				comment: 1,
				init: {
					//plugins: ' lists image colorpicker textcolor wordcount contextmenu autoresize',
					plugins: ' lists image wordcount autoresize',
					toolbar: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent  | undo redo | link unlink image  | removeformat ',
					branding: false,
					min_height: 300,
					images_upload_url: '/demo/upimg.php',
					language: 'zh_CN', //注意大小写
				},
				options: [],
			}
		},
		created() {
			this.getCategory();
			this.getArticle();
		},
		methods: {
			async getCategory() {
				let res = await category({
					page: 1,
					limit: 5000
				});
				res.data.list.map((item) => {
					if (item.status == 1) {
						item.status = false;
					} else {
						item.status = true;
					}
				});
				this.options = res.data.list;
			},
			async getArticle() {
				let res = await article({
					id: 1
				});
				this.article = res.data

			},
			handleAvatarSuccess(res, file) {
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			}
		}
	}
</script>
<style lang="less">
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		width: 100px;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		text-align: center;
	}
</style>
