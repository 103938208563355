import {
	http
} from '../http.js'

//��Ʒ�б�
export function index(option) {
	return http('admin/goods/index', 'post', option)
}
//��Ʒ����
export function details(option) {
	return http('admin/goods/details', 'post', option)
}
//��Ʒ����
export function add(option) {
	return http('admin/goods/add', 'post', option)
}
//��Ʒ����
export function edit(option) {
	return http('admin/goods/edit', 'post', option)
}
//��Ʒɾ��
export function delect(option) {
	return http('admin/goods/delect', 'post', option)
}
//��Ʒ����
export function category(option) {
	return http('admin/goods.Category/index', 'post', option)
}
//��Ʒ����
export function property(option) {
	return http('admin/goods.Property/index', 'post', option)
}
//������ͼ
export function setCover(option) {
	return http('admin/goods/setCover', 'post', option)
}
//ɾ��ͼƬ
export function delImage(option) {
	return http('admin/goods/delImage', 'post', option)
}
