import {
	createApp
} from 'vue'
import App from './App.vue'

//引用router.js
import router from './router.js'
//导入全局样式表
import './assets/css/global.css'

//导入组件vue-table-with-tree-grid
import ZkTable from 'vue-table-with-tree-grid'

import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
// 设置 Day.js 国际化
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'


 
const app = createApp(App)
let c =(value)=>{
    console.log(JSON.parse(JSON.stringify(value)));
};
app.config.globalProperties.$systemName = '用户管理系统'
app.config.globalProperties.$BASE_URL = 'https://doctor.123xk.cn'
app.config.globalProperties.$c = c

app.use(ElementPlus, {
	locale
})
app.use(router)
app.use(ZkTable)
app.mount('#app')


