import {
	http
} from '../http.js'
/**
 * 获取疾病列表
 * @param {Object} option
 */
export function diseaseList(option) {
	return http('admin/disease/index', 'post', option)
}
/**
 * 获取详情
 * @param {Object} option
 */
export function disease(option) {
	return http('admin/disease/details', 'post', option)
}
/**
 * 编辑
 * @param {Object} option
 */
export function diseaseEdit(option) {
	return http('admin/disease/edit', 'post', option)
}
/**
 * 新增
 * @param {Object} option
 */
export function diseaseAdd(option) {
	return http('admin/disease/add', 'post', option)
}
/**
 * 删除
 * @param {Object} option
 */
export function diseaseDelete(option) {
	return http('admin/disease/delect', 'post', option)
}
/**
 * 疾病关键词编辑
 * @param {Object} option
 */
export function saveKeyword(option) {
	return http('admin/disease/saveKeyword', 'post', option)
}
