import {
	http
} from '../http.js'

/**
 * 角色列表
 * @param {Object} option
 */
export function index(option) {
	return http('admin/role/index', 'post', option)
}
/**
 * 角色新增
 * @param {Object} option
 */
export function add(option) {
	return http('admin/role/add', 'post', option)
}
/**
 * 角色编辑
 * @param {Object} option
 */
export function edit(option) {
	return http('admin/role/edit', 'post', option)
}
/**
 * 删除角色
 * @param {Object} option
 */
export function delect(option) {
	return http('admin/role/delect', 'post', option)
}
