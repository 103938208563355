<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view></router-view>
    <!-- <login></login> -->
  </div>
</template>

<script>
// import login from './components/login.vue'//引入组件

export default {
  name: 'app',
  // components: {
  //   login, //定义组件名称
  // }
}
</script>

<style>

</style>
