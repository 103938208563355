<template>
	<div class="login_conter">
		<div class="login_box">
			<div class="avatar_box">
				<div class="avatar"><img src="../assets/images/logo.png" alt="头像" /></div>
				<div class="login_text">老中E管理后台</div>
			</div>
			<!-- 登录表单区域 -->
			<el-form ref="lognFormref" :model="loginForm" :rules="rules" label-width="0px" class="login_form">
				<el-form-item prop="username">
					<el-input v-model="loginForm.username" prefix-icon="el-icon-user"></el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input v-model="loginForm.password" prefix-icon="el-icon-lock" type="password"></el-input>
				</el-form-item>
				<el-row class="btns">
					<el-button type="primary" @click="login()" :plain="true">登录</el-button>
					<el-button type="info" @click="resetForm()">重置</el-button>
				</el-row>
			</el-form>
		</div>
	</div>
</template>
<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		login
	} from "../api/index.js"
	export default {
		data() {
			return {
				loginForm: {
					username: 'wangrubiao',
					password: '06291013',
				},
				rules: {
					username: [{
							required: true,
							message: '请输入账号',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 11,
							message: '长度在 6 到 11 个字符',
							trigger: 'blur',
						},
					],
					//验证密码
					password: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							min: 0,
							max: 10,
							message: '长度在 0 到 10 个字符',
							trigger: 'blur',
						},
					],
				},
			}
		},
		methods: {
			//表单重置
			resetForm() {
				console.log(this)
				//通过获取表单区域
				this.$refs.lognFormref.resetFields()
			},
			async login() {
				let res = await login({
					username: this.loginForm.username,
					password: this.loginForm.password,
				});
				window.sessionStorage.setItem('token', res.data.token)
				this.$router.push('./Home')
				ElMessage.success({
					message: '恭喜你，登录成功',
					type: 'success',
				})
			},
		},
	}
</script>
<style lang="less" scoped>
	.login_conter {
		//background: url(../assets/images/bj01.png);
		background: url(../assets/images/bg.png);
		height: 100%;
		width: 100%;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-attachment: fixed;
	}

	.login_box {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 450px;
		height: 300px;
		border-radius: 10px;
		background-color: #fff;
	}
	.avatar_box{
		display: flex;
		flex-direction: column;
		align-items: center;
		.login_text{
			margin-top: 80px;
			font-weight: bold;
			color: #c6a28a;
		}
	}
	.login_box .avatar {
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 10px;
		width: 130px;
		height: 130px;
		border: 1px solid #eee;
		box-shadow: 0 0 10px #ddd;
		border-radius: 50%;
		background-color: #fff;
	}

	.login_box .avatar img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: #eee;
	}

	.login_form {
		position: absolute;
		bottom: 20px;
		width: 100%;
		padding: 0 20px;
		box-sizing: border-box;
	}

	.btns {
		display: flex;
		justify-content: flex-end;
	}
</style>
