import {
	http
} from '../http.js'
/**
 * 获取医院列表
 * @param {Object} option
 */
export function hospList(option) {
	return http('admin/hosp/index', 'post', option)
}
/**
 * 获取医院详情
 * @param {Object} option
 */
export function hosp(option) {
	return http('admin/hosp/details', 'post', option)
}
/**
 * 编辑医院
 * @param {Object} option
 */
export function hospEdit(option) {
	return http('admin/hosp/edit', 'post', option)
}
/**
 * 新增医院
 * @param {Object} option
 */
export function hospAdd(option) {
	return http('admin/hosp/add', 'post', option)
}
/**
 * 获取科室列表
 * @param {Object} option
 */
export function classList(option) {
	return http('admin/class/index', 'post', option)
}
/**
 * 编辑科室
 * @param {Object} option
 */
export function classEdit(option) {
	return http('admin/class/edit', 'post', option)
}
/**
 * 新增科室
 * @param {Object} option
 */
export function classAdd(option) {
	return http('admin/class/add', 'post', option)
}