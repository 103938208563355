<template>
	<!-- 面包屑导航 -->
	<el-breadcrumb>
		<el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
		<el-breadcrumb-item>文章管理</el-breadcrumb-item>
		<el-breadcrumb-item>文章列表</el-breadcrumb-item>
	</el-breadcrumb>

	<!-- 卡片视图区域 -->
	<el-card>
		<!-- 搜索区域 -->
		<el-form v-if="search.status">
			<el-row :gutter="50">
				<template v-for="(item,index) in search.list" :key="item.index">
					<el-col v-if="search.list[index].search==true" :span="7">
						<el-form-item :label="item.name" v-if="item.type=='text'">
							<el-input size="small" :placeholder="item.name" v-model="search.list[index].value" clearable
								@clear="getTableData()">
							</el-input>
						</el-form-item>
				
						<el-form-item :label="item.name" v-if="item.type=='time'">
							<el-date-picker size="small" v-model="search.list[index].value" type="daterange"
								:shortcuts="shortcuts" range-separator="至" start-placeholder="开始日期"
								end-placeholder="结束日期" unlink-panels format="YYYY-MM-DD" value-format="YYYY/MM/DD">
							</el-date-picker>
						</el-form-item>
						<el-form-item :label="item.name" v-if="item.type=='select'">
							<el-select size="small" v-model="search.list[index].value"
								@change="searchSelect(search.list[index].value)" placeholder="请选择" clearable>
								<el-option v-for="vo in options" :key="vo.value" :label="vo.label" :value="vo.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</template>
				<el-col :span="4">
					<el-form-item>
						<el-button size="small" @click="getTableData(true)" icon="el-icon-search">搜索</el-button>
						<el-button size="small" icon="el-icon-search">重置</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<!-- 搜索区域end -->
		<!-- table区域 -->
		<el-button :model="ruleForm" type="primary" @click="showAddDialog()">添加</el-button>
		<el-button type="danger" @click="delect()">删除</el-button>
		<!-- <el-button type="warning" @click="delect()">导出</el-button> -->
		<el-table :data="tableData" @selection-change="tableSelect" border stripe>
			<el-table-column type="selection" width="55">
			</el-table-column>
			<el-table-column type="index" :index='indexMethod' label="编号"></el-table-column>
			<el-table-column v-for="(items,i) in search.list" :key="items.name" :prop="i" :label="search.list.[i].name">

				<template #default="scope">
					<div v-if="items.type=='array'">
						<el-tag type="info" v-for="arr in scope.row[i]" :key="arr.id">
							{{arr.name}}
						</el-tag>
					</div>
					<div v-else-if="search.list.[i].type=='select'">
						<el-switch v-model="scope.row.[i]" @change="switchChange(scope.row)" inactive-color="#c0c0c0"
							active-color="#724447" :active-value="0" :inactive-value="1">
						</el-switch>
					</div>
					<div v-else>
						{{scope.row.[i]}}
					</div>
				</template>
			</el-table-column>
			
			<el-table-column label="操作" width="180px">
				<template #default="scope">
					<el-button type="primary" icon="el-icon-edit" size="mini"
						@click="showEditDialog(scope.$index, scope.row)">
					</el-button>
					<el-button type="danger" icon="el-icon-delete" size="mini" @click="removrRow(scope.row.id)">
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页区域 -->
		<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="queryInfo.pagenum" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pagesize"
			layout="total, sizes, prev, pager, next, jumper" :total="total">
		</el-pagination>
	</el-card>
</template>
<script>
	import {
		index,
		edit,
		details,
		delect
	} from "../../api/article.js"
	export default {
		data() {
			return {

				options: [{
					value: false,
					label: '正常'
				}, {
					value: true,
					label: '隐藏'
				}],
				shortcuts: [{
					text: '最近一周',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						return [start, end]
					}
				}, {
					text: '最近一个月',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						return [start, end]
					}
				}, {
					text: '最近三个月',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						return [start, end]
					}
				}],
				//获取表格参数对象
				queryInfo: {
					query: {},
					//当前的页面
					pagenum: 1,
					//当前每页显示多少条数据
					pagesize: 5,
				},
				//表格数据
				tableData: [],
				//选中的row
				tableRow: [],
				//支持搜索的字段
				search: {
					status: true, //开启表单搜索
					list: {
						title: {
							show: true, //表格是否显示字段
							search: true,
							modal: true,
							type: 'text', //列类型,决定了搜索和显示的样式
							name: '标题', //列名称
							value: '', //当前搜索情况下的值
							//验证规则
							rules: [{
									required: true,
									message: '请输入标题',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},
						articleColumn: {
							show: true, //表格是否显示字段
							search: true,
							modal: true,
							type: 'array',
							name: '分 组',
							value: '',
							option: [], //初始化下拉数据
							rules: [{
								required: true,
								message: '请选择',
								trigger: 'blur',
							}]
						},
						update_time: {
							type: 'time',
							search: true,
							show: true, //表格是否显示字段
							name: '更新日期',
							modal: true,
							value: '',
							//验证规则
							rules: [{
									required: true,
									message: '请输入日期',
									trigger: 'blur',
								},
								{
									min: 3,
									max: 20,
									message: '长度在 3 到 10 个字符',
									trigger: 'blur',
								},
							]
						},
					}

				},
				//状态
				total: 0,

				//需要被分配角色的用户信息
				userinfo: {},
				//所有角色的数据列表
				rolesList: [],
				//已选中的角色id值
				selectedRoleId: {},
				//当前编辑对象
				editForm: {},
				//表单新增对象
				ruleForm: {},
				editRow: ''
			}
		},
		created() {
			this.getTableData(false)
		},
		methods: {
			indexMethod(index) {
				let curpage = this.queryInfo.pagenum; //单前页码，具体看组件取值
				let limitpage = this.queryInfo.pagesize; //每页条数，具体是组件取值
				return (index + 1) + (curpage - 1) * limitpage;
			},
			async getTableData(value) {
				let d = {};
				if(value){
					Object.keys(this.search.list).forEach((key) => {
						if (this.search.status == true) {
							if (this.search.list[key].value != '' && this.search.list[key].search==true) {
								console.log(key+'资料',this.search.list[key].value);
								d[key] = {
									'value': this.search.list[key].value,
									'type': this.search.list[key].type,
								}
							}
						}
					});
				}
				let res = await index({
					filter: JSON.stringify(d),
					page: this.queryInfo.pagenum,
					limit: this.queryInfo.pagesize
				});
				this.tableData = res.data.list
				this.total = res.data.pagination.total
			},
			/**
			 * @param {Object} val
			 * 监听pagesize改变事件
			 */
			handleSizeChange(val) {
				this.queryInfo.pagesize = val
				this.getTableData()
			},
			/**
			 * @param {Object} val
			 * 监听页码值改变事件
			 */
			handleCurrentChange(val) {
				this.queryInfo.pagenum = val
				this.getTableData()
			},
			/**
			 * @param {Object} row
			 * 监听swit开关的改变状态
			 */
			async switchChange(row) {
				//开始实现你的伟大逻辑...
				console.log('这一句', row.articleColumn[0].id);
				row.category_id = row.articleColumn[0].id;
				await edit(row);
				return this.$message.success('切换成功');
			},
			//监听添加用户对话框的关闭事件，关闭清空表单数据
			addDialogClosed() {
				console.log('关闭了窗口!');
				this.$refs.ruleFormRef.resetFields()
			},
			/**
			 * 表单新增
			 */
			addForm() {
				//开始实现你的伟大逻辑...
				console.log('表单数据', JSON.stringify(this.ruleForm));
				this.$refs.ruleFormRef.validate(async (valid) => {
					console.log(valid);
					if (valid) {
						console.log('验证通过！！！');
					} else {
						console.log('验证不通过');
						return false;
					}
					//验证通过
				})
			},
			showAddDialog() {
				this.$router.push({
					path: './add'
				})
			},
			/**
			 * 表单模态框编辑
			 */
			async showEditDialog(index, row) {
				this.$router.push({
					path: './edit',
					query: {
						ids: row.id
					}
				})
				this.editRow = index;
				let res = await details({
					id: row.id
				});
				this.editForm = res.data;
			},
			/**
			 * 关闭表单编辑模态框
			 */
			edotDialogClosed() {
				this.$refs.editFormRef.resetFields()
			},
			/**
			 * 表单编辑
			 */
			tableEditData() {
				console.log('编辑的对象', this.editForm);
				this.$refs.editFormRef.validate(async (valid) => {
					console.log(valid)
					if (!valid) return;
					await edit({
						id: this.editForm.id,
						title: this.editForm.title,
						status: this.editForm.status
					});
					//编辑后静态更新table字段
					this.tableData[this.editRow].status = this.editForm.status;
					this.tableData[this.editRow].title = this.editForm.title;
					//关闭对话框
					this.editDealogVisible = false
					this.$message.success('修改用户信息成功')
				})
			},

			/**
			 * 选项改变触发事件
			 * @param {Object} row
			 */
			tableSelect(row) {
				this.tableRow = [];
				Object.keys(row).forEach((key) => {
					this.tableRow[key] = row. [key].id;
					//console.log(this.tableRow[key]);
				});

			},
			/**
			 * 删除选中行
			 * @param {Object} id
			 */
			async removrRow(id) {
				//弹框提示
				const confirmResult = await this.$confirm(
					'此操作将永久删除该文章, 是否继续?',
					'提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					}
				).catch((err) => err)
				if (confirmResult != 'confirm') {
					//取消操作
				} else {
					await delect({
						ids: [id]
					});
					this.getTableData(false)
					this.$message.success('删除成功');	
				}
			},
			/**
			 * 删除选中行
			 */
			async delect() {
				if (this.tableRow.length <= 0) {
					return this.$message.error("请选择删除行")
				} else {
					//弹框提示
					const confirmResult = await this.$confirm(
						'此操作将永久删除选中文章, 是否继续?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					).catch((err) => err)
					if (confirmResult != 'confirm') {
						//取消操作
					} else {
						await delect({
							ids: this.tableRow
						});
						this.getTableData(false)
						this.$message.success('删除成功');	
					}
					
				}

			},
		},
	}
</script>
<style lang="less" scoped>
	.el-pagination {
		margin-top: 15px;
	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
</style>
