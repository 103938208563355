import {
	http
} from '../http.js'

/**
 * 获取所有菜单列表
 * @param {Object} option
 */
export function index(option) {
	return http('admin/menu/index', 'post', option)
}
/**
 * 角色编辑
 * @param {Object} option
 */
export function edit(option) {
	return http('admin/menu/edit', 'post', option)
}
/**
 * 获取所有菜单
 * @param {Object} option
 */
export function getMenus(option) {
	return http('admin/menu/getMenus', 'post', option)
}
